import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";

import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";



console.log("I am in AddStocks.js");

const Delete = ({ signOut }) => {
    console.log("I am in AddStocks.js->AddStock function");
  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    fetchNotes();
  }, []);

  async function fetchNotes() {
    const apiData = "";
    const stocksFromAPI = apiData.data.listStocks.items;
    setStocks(stocksFromAPI);
  }

  async function add(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      stock: form.get("stockName"),
    };
    //await API.graphql({
    //  query: "",
    //  variables: { input: data },
    //});
    fetchNotes();
    event.target.reset();
  }

  async function deleteNote({ id }) {
    /*const newNotes = notes.filter((note) => note.id !== id);
    setNotes(newNotes);
    await API.graphql({
      query: deleteNoteMutation,
      variables: { input: { id } }, 
    });*/
  }

  return (
    <View className="AppFullScreen">
      <Heading level={1}>Add Stock</Heading>
      <View as="form" margin="3rem 0" onSubmit={add}>
        <Flex direction="row" justifyContent="center">
          <TextField
            stockName="stockName"
            placeholder="stockName"
            label="Stock Name"
            labelHidden
            variation="quiet"
            required
          />
          
          <Button type="submit" variation="primary">
            Add Stock
          </Button>
        </Flex>
      </View>
      <Heading level={2}>Current Notes</Heading>
      <View margin="3rem 0">
        {stocks.map((note) => (
          <Flex
            key={note.id || note.name}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text as="strong" fontWeight={700}>
              {note.name}
            </Text>
            <Text as="span">{note.description}</Text>
            <Button variation="link" onClick={() => deleteNote(note)}>
              Delete note
            </Button>
          </Flex>
        ))}
      </View>
      <Button onClick={signOut}>Sign Out</Button>
    </View>
  );
};

export default withAuthenticator(Delete);