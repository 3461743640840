import React, { useState, useEffect, useRef } from "react";

const AiResponse = () => {
  const [response, setResponse] = useState("");

  useEffect(() => {
    const storedResponse = localStorage.getItem("aiResponse");
    if (storedResponse) {
      // Example: Remove any 'window.open' calls
      const modifiedResponse = storedResponse.replace(/window\.open\([^)]*\);?/g, ''); 
      setResponse(modifiedResponse); 
    } else {
      setResponse("No response available.");
    }
  }, []);

  return (
    <div>
      <h1>AI Response</h1>
      <div dangerouslySetInnerHTML={{ __html: response }} />
    </div>
  );
};

export default AiResponse;