import logo from './logo.svg';
import './App.css';

import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import React, { useEffect, useRef, useState } from 'react'
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate } from 'react-router-dom';
import Settings from './Settings';
import { Button } from '@aws-amplify/ui-react';
import { useActiveButton } from './ActiveButtonContext';
import { printConsole } from './lib/utility';

const myAPI="mktappapi";
let path = '/getSecuritiesInfo'; 

const EditSecurityInfo = ({signOut}) => {
  let [action, setAction] = useState("search");
  const [input, setInput] = useState("");
  let [displayMessage, setDisplayMessage] = useState("");
  let symbolsDetails = useRef([]);
  let info = useRef({});
  const [formData, setFormData] = useState({
    security: '',
    securityName: '',
    type: '',
    description: '',
    exchange: '',
    leveraged: '',
  });

  const [formDataSearch, setFormDataSearch] = useState({
    securityName: ''
  });

  const { activeButton, setActiveButton } = useActiveButton();

  const handleSubmit = async (event) => {
    await printConsole("Executing handleSubmit");
    event.preventDefault();
    // Handle the form submission
    console.log(formData); 
    setDisplayMessage("Updating ...");
    
    await setDisplayMessage("Updating ...");
    await event.preventDefault();
    // Handle the form submission
    await console.log(formData); 
    let name = await encodeURI(formData.securityName);
    printConsole("name = " + name);
    let description = await encodeURI(formData.description)
    printConsole("description = " + description);
    let path = '/editsecurityinfo'; 
    var passApiStr=await path + "/" + formData.security.toUpperCase().trim() + "/" + name.trim() + "/" + formData.type + "/" + description.trim() + "/" + formData.exchange + "/" + formData.leveraged;
    await printConsole("handleSubmit : passApiStr = "+ passApiStr);
    //await Promise.resolve(await get(myAPI, passApiStr))
    const response = await get({
      apiName: myAPI,
      path: passApiStr,
    }).response;
    let resp = response.body.text();
    await Promise.resolve(resp)
      .then(async () =>{
        await setDisplayMessage(resp);
        await printConsole("downloadStocks : displayMessage = " + displayMessage);
    })
    .catch(error => {
      printConsole(error.message);
    }) 
  };

  const handleSubmitSearch = async (event) => {
    await event.preventDefault();
    // Handle the form submission
    await console.log(formDataSearch);
    
    await setDisplayMessage("Searching ...");
    let symbol = formDataSearch.securityName.trim().toUpperCase();
    path = '/getSymbolsDetails';
    var passApiStr=await path;
    await console.log("handleSubmitSearch : passApiStr = "+ passApiStr);
    //await Promise.resolve(await get(myAPI, passApiStr))
    const response = await get({
      apiName: myAPI,
      path: passApiStr,
    }).response;
    let resp = response.body.json();
    await Promise.resolve(resp)
      .then(async () =>{
        
        await console.log("handleSubmitSearch : received symbols details = ");
        await console.log(resp);
        //symbolsDetails.current = await JSON.stringify(resp);
        symbolsDetails.current = await resp;
        printConsole("handleSubmitSearch : symbolsDetails.current.length = " + symbolsDetails.current.length);
        console.log("handleSubmitSearch : symbolsDetails.current = " , symbolsDetails.current);
    })
    .catch(error => {
      printConsole(error.message);
      setDisplayMessage(error.message);
    }) 

    for (let symInd = 0; symInd < symbolsDetails.current.length; symInd++) {
      if (symbolsDetails.current[symInd].symbol === symbol) {
        info.current = symbolsDetails.current[symInd];
        symInd = symbolsDetails.current.length;
      }
    }
    if (info.current.hasOwnProperty('name')) {
      formData.security = info.current.symbol;
      formData.securityName = info.current.name;
      formData.type = info.current.type;
      formData.description = info.current.description;
      formData.exchange = info.current.exchange;
      formData.leveraged = info.current.leveraged;
      await setAction("update");
      setDisplayMessage("");
    } else {
      setDisplayMessage(symbol + " - Not Found");
    }
    printConsole ("handleSubmitSearch : info =");
    console.log (info.current);
  };

  const navigate = useNavigate();

  useEffect(()=>{
    navigate(input);
  },[action, displayMessage])

  const handleClear = () => {
    setFormData({
        security: '',
        securityName: '',
        type: '',
        description: '',
        exchange: '',
        leveraged: ''
    });
    setDisplayMessage("");
  };

  const handleChange = (event) => {
      setFormData({
          ...formData,
          [event.target.name]: event.target.value
      });
      setDisplayMessage("");
  };

  const handleChangeSearch = (event) => {
    setFormDataSearch({
        ...formDataSearch,
        [event.target.name]: event.target.value
    });
    setDisplayMessage("");
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh'
  };

  const formStyleSearch = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20vh'
  };

  const inputStyle = {
      margin: '10px',
      padding: '10px',
      width: '300px',
      borderRadius: '5px',
      border: '1px solid #ddd'
  };

  const buttonStyle = {
      margin: '10px',
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#4CAF50',
      color: 'white',
      cursor: 'pointer'
  };

  return (
    <div>
      {action === "search" ? (
        <form onSubmit={handleSubmitSearch} style={formStyleSearch}>
          <div>{(
            <div className="blink_me" style={{ fontSize: '18px' }}>{displayMessage}</div>
          )}</div>
          
          <input 
              type="text" 
              name="securityName" 
              value={formDataSearch.securityName} 
              onChange={handleChangeSearch} 
              required 
              placeholder="Security Name"
              style={inputStyle}
          />
          <div className="menu-row" style={{ width: '33%', display: 'flex', justifyContent: 'center' }}>
              <button type="submit" onClick={handleSubmitSearch} style={buttonStyle}>Submit</button>
          </div>
        </form>
      ) : (
      <form onSubmit={handleSubmit} style={formStyle}>
        <div>{(
          <div className="blink_me" style={{ fontSize: '18px' }}>{displayMessage}</div>
        )}</div>
          
          <strong>Symbol</strong>
          <input 
              type="text" 
              name="security" 
              value={formData.security} 
              onChange={handleChange} 
              required 
              placeholder="Security"
              style={inputStyle}
              disabled 
          />
          <strong>Long Name</strong>
          <input 
              type="text" 
              name="securityName" 
              value={formData.securityName} 
              onChange={handleChange} 
              required 
              placeholder="Security Name"
              style={inputStyle}
          />
          <strong>Type</strong>
          <select 
              name="type" 
              value={formData.type} 
              onChange={handleChange} 
              required
              style={inputStyle}
          >
              <option value="">Select Type</option>
              <option value="ETF">ETF</option>
              <option value="Stock">Stock</option>
          </select>
          <strong>Description</strong>
          <input 
              type="text" 
              name="description" 
              value={formData.description} 
              onChange={handleChange} 
              placeholder="Description"
              style={inputStyle}
          />
          <strong>Exchange</strong>
          <select 
              name="exchange" 
              value={formData.exchange} 
              onChange={handleChange} 
              required
              style={inputStyle}
          >
              <option value="NYSEARCA">NYSEARCA</option>
              <option value="NASDAQ">NASDAQ</option>
              <option value="NYSE">NYSE</option>
              <option value="BATS">BATS</option>
              <option value="NYSEAMERICAN">NYSEAMERICAN</option>
              <option value="TSE">TSE</option>
              <option value="OTCMKTS">OTCMKTS</option>
              <option value="ASX">ASX</option>
          </select>
          <strong>Leveraged</strong>
          <select 
              name="leveraged" 
              value={formData.leveraged} 
              onChange={handleChange} 
              required
              style={inputStyle}
          >
              <option value="NO">NO</option>
              <option value="YES">YES</option>
          </select>
          <div className="menu-row" style={{ width: '33%', display: 'flex', justifyContent: 'center' }}>
            <button type="submit" style={buttonStyle}>Submit</button>
            <button type="button" onClick={handleClear} style={buttonStyle}>Clear</button>
          </div>
      </form>
      )}
    </div>
  )
}

export default EditSecurityInfo;