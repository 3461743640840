import logo from './logo.svg';
import './App.css';

import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import React, { useEffect, useState, useRef, useContext } from 'react'
import { AgGridReact} from "ag-grid-react";
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate } from 'react-router-dom';
import Settings from './Settings';
import { useActiveButton } from './ActiveButtonContext';
import {ScreenSizeContext} from './App'
import { useLocation } from 'react-router-dom';
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";

const myAPI = "api30769188"; 

const FindIcEtfsGrid = ({signOut}) => {
  console.log("Executing FindIcEtfsGrid");
  const location = useLocation();
  const data = location.state?.data || [];
  console.log("data = ", data);

  const navigate = useNavigate();

  let counter = useRef(0);

  const [rowDataState,setRowDataSate]=useState([]);
  let rowdata = [];

  for (let dataInd = 0; dataInd < data.length; dataInd++) {
    let quoteSpread = data[dataInd]["quoteSpread"].toFixed(2);
    quoteSpread = parseFloat(quoteSpread);
    rowdata[dataInd]={
      security: data[dataInd]["symbol"],
      marketCap: parseInt(data[dataInd]["marketCap"]),
      quoteSpread: quoteSpread,
      bid: parseFloat(data[dataInd]["bid"]),
      ask: parseFloat(data[dataInd]["ask"]),
      name: data[dataInd]["name"],
      description: data[dataInd]["description"],
      exchange: data[dataInd]["exchange"]
    }
  }

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const numberComparator = (valueA, valueB) => {
    return valueA - valueB;
  };

  const ICButtonRenderer = (props) => {
    return (
      <button
        style={{ width: '100%', height: '100%' }}
        onClick={() => {
          navigate('/generateOptionsLegs', { state: { security: props.data.security } });
        }}
      >
        IC
      </button>
    );
  };

  function getSymInfo(security) {
    console.log("Executing getSymInfo");
    console.log("getSymInfo : security = ", security);
    console.log("getSymInfo : rowDataState = ", rowDataState);
    let symInfo = rowDataState.find(sym => sym.security === security);
    if (symInfo) {
      return symInfo;
    } else {
      return { exchange: '', name: '', description: '', type: '' };
    }
  }

  class CustomCellRenderer extends React.Component {
    render() {
      console.log("Executing CustomCellRenderer");
      console.log("CustomCellRenderer : this.props = ", this.props);
      const { value } = this.props;
      console.log("CustomCellRenderer : value = ", value);
      let security = value.toString();
      
      let info = getSymInfo(security);
      console.log("CustomCellRenderer : info = ", info);

      let exchange = info.exchange;
      let name = info.name;
      let description = info.description;
      if (description === "blank"){
        description = "";
      } 
      let symTitle;
      if (description.length > 0) {
        symTitle = name + "\n" + description;
      } else {
        symTitle = name;
      }
      
      console.log("CustomCellRenderer : symTitle = ", symTitle);
      console.log("CustomCellRenderer : exchange = ", exchange);
      console.log("CustomCellRenderer : security = ", security);
      return (
        <a
          href={`https://www.google.com/finance/quote/${security}:${exchange}?window=1Y`}
          target="_blank"
          rel="noopener noreferrer"
          title={symTitle}
        >
          {security}
        </a>
      );
    }
  }

  const columns=[
    {headerName: "Security", field: "security", sortable: true, filter: true, pinned: true, minWidth:10, maxWidth:90, resizable:true, editable: false,cellRenderer: CustomCellRenderer,},
    { 
      headerName: "Market Cap", 
      field: "marketCap", 
      sortable: true, 
      pinned: true, 
      filter: 'agNumberColumnFilter', 
      minWidth: 50, 
      maxWidth: 150, 
      editable: false, 
      resizable: true,
      valueFormatter: (params) => formatNumberWithCommas(params.value), // Apply formatter
      comparator: numberComparator // Apply custom comparator
    },
    {headerName: "Bid", field: "bid", sortable: true, pinned: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:100, editable: false, resizable:true,},
    {headerName: "Ask", field: "ask", sortable: true, pinned: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:100, editable: false, resizable:true,},
    {headerName: "Quote Spread", field: "quoteSpread", sortable: true, pinned: true, filter: 'agNumberColumnFilter', minWidth:50, maxWidth:150, editable: false, resizable:true,},
    { headerName: "IC", field: "IC", cellRenderer: ICButtonRenderer, minWidth: 50, maxWidth: 100, resizable: true },
  ];

  useEffect (() => {
    console.log("Executing useEffect");
    console.log("rowdata = ", rowdata);
    if (rowDataState.length === 0 && counter.current === 0) {
      counter.current++;
      setRowDataSate(rowdata);
    }
    
  })

  return (
    
    <View className="AppFullScreen">
      <div className="ag-theme-balham" style={{width: '90%', height:500, maxWidth: '700px'}}>
        <div className="green_text">Iron Condor - Find ETFs</div>
        <AgGridReact
          //rowClassRules={rowClassRules}
          rowData={rowDataState}
          columnDefs={columns}
          suppressRowClickSelection={true}
          rowSelection={'multiple'}
          //ref={gridRef}
          //onGridReady={onGridReady}
          //onFilterChanged={onFilterChanged}
          //onFirstDataRendered={onFirstDataRendered}
          //onSelectionChanged={onSelectionChanged}
        />
      </div>
    </View>
  );
  
}

export default FindIcEtfsGrid;