import logo from './logo.svg';
import './App.css';

import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import React, { useEffect, useState } from 'react'
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate } from 'react-router-dom';
import Settings from './Settings';
import { Button } from '@aws-amplify/ui-react';
import { useActiveButton } from './ActiveButtonContext';

const myAPI = "api30769188"
const path = '/customers'; 

const MainMenuBuyWrite = ({signOut}) => {
  const [input, setInput] = useState("");
  const [customers, setCustomers] = useState([]);
  //const [activeButton, setActiveButton] = useState('inTheMoney');
  const { activeButton, setActiveButton } = useActiveButton();

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  const navigate = useNavigate();

  useEffect(()=>{
    navigate(input);
  },[input, activeButton])

//changed titles
  //Function to fetch from our backend and update customers array
  function getCustomer(e) {
    let customerId = e.input
    get(myAPI, path + "/" + customerId)
       .then(response => {
         console.log(response)
         let newCustomers = [...customers]
         newCustomers.push(response)
         setCustomers(newCustomers)

       })
       .catch(error => {
         console.log(error)
       })
  }

  return (
    
<div className='MenuStyle'>
<h1 style={{ display: 'none' }}>Main Menu Buy Write</h1>
  <div className="menu-row">
    <Button style={{ backgroundColor: activeButton === 'inTheMoney' ? 'green' : 'grey', width: '50%', marginTop: "1%", marginBottom:"1%", color: 'white' }} onClick={() => {handleClick('inTheMoney')}}>
      In The Money
    </Button>
    <Button style={{ backgroundColor: activeButton === 'outOfTheMoney' ? 'green' : 'grey', width: '50%', marginTop: "1%", marginBottom:"1%", color: 'white' }} onClick={() => {handleClick('outOfTheMoney')}}>
      Out Of The Money
    </Button>
  </div>
  <div className="menu-row">
    <label style={{ width: '200%', marginTop: "1%", marginBottom:"1%", backgroundColor: "lightgray", fontWeight: "bold"}} onClick={()=>{
    }}>
      ETFs
    </label>
  </div>
  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/list"); 
    }}>
      List
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runAll"); 
    }}>
      Analysis
    </Button>
  </div>
  <div className="menu-row">
    <label style={{ width: '200%', marginTop: "1%", marginBottom:"1%", backgroundColor: "lightgray", fontWeight: "bold"}} onClick={()=>{
    }}>
      Securities
    </label>
  </div>
  

  <div className="menu-row">
    
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runWallStreetBets"); 
    }}>
      WallStreet Bets
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runGrowthTechnology"); 
    }}>
      Growth Technology
    </Button>
  </div>
  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runMostActive"); 
    }}>
      Most Active
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runMostWatched"); 
    }}>
      Most Watched
    </Button>
    
  </div>
  <div className="menu-row">
  <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runDayGainers"); 
    }}>
      Most Gainers
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runDayLosers"); 
    }}>
      Most Losers
    </Button>
   
  </div>
  <div className="menu-row">
  <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runAggressive"); 
    }}>
      Small Caps Aggressive
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runSmall"); 
    }}>
      Small Caps Gainers
    </Button>
    
  </div>
  <div className="menu-row">
  <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runUndervalued"); 
    }}>
      Undervalued Large Caps
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runUnderValuedGrowth"); 
    }}>
      Undervalued Growth
    </Button>
    
  </div>
  <div className="menu-row">
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/find"); 
    }}>
      Find Expiration
    </Button>
    <Button backgroundColor="lightgreen" style={{ width: '50%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
      setInput("/runOne"); 
    }}>
      Custom List
    </Button>
  </div>
  <div className="menu-row">
      <Button title="My Portfolio" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%"}} onClick={()=>{
        setInput("/myPortfolio"); 
    }}>
        <img
        src="briefcase.png"
        alt="Briefcase Icon"
        title="My Portfolio" // Hover-over text
        style={{ marginRight: '5px', width: '33%' }}
      />  
    </Button>
 
    <Button title="Settings" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%"}} onClick={()=>{
      setInput("/editSecurityMenu"); 
    }}>
      <img src="gear.png" alt="Briefcase Icon" title="Settings" style={{ marginRight: '5px', width: '33%' }} />
    </Button>
    
    
    <Button title="Sign Out" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={signOut}>
      <img src="exit.png" alt="Briefcase Icon" title="Sign Out" style={{ marginRight: '5px', width: '33%' }} />
    </Button>

  </div>
    
</div>
  )
}

export default MainMenuBuyWrite;