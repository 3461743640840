import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef, useMemo } from 'react'
import * as Auth from '@aws-amplify/auth';

import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { gridColumns } from './lib/grid';
import { sepElements } from "./lib/common";
import { loadRowData } from "./lib/common";
import { getUserInfo } from "./lib/common";
import { listFiles } from "./lib/common";
import { rowClassRules } from "./lib/grid";
import { downloadFile } from "./lib/common";
import { useActiveButton } from './ActiveButtonContext';
import { strategyetfs } from "./lib/common";
import { strategyLongTerm } from "./lib/common";

import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { type } from "@testing-library/user-event/dist/type";
//const axios = require('axios');
import axios from 'axios';

const myAPI="mktappapi";
let path = '/strategy';

const RunAllLongTerm = ({ signOut }) => {
  console.log("Executing RunAllLongTerm");
  const navigate = useNavigate();
  const { activeButton } = useActiveButton();
  let numberOfChecked=useRef(0);

  let email = useRef();
  const [days, setDays]=useState();
  const [forceRender, setForceRender]=useState(false);
  const [daysOffset, setDaysOffset]=useState();
  const [minInterest, setMinInyerest]=useState();
  const [maxInterest, setMaxInterest]=useState();
  const [settingsStr, setSettingsStr]=useState({"days":"30","daysOffset":"10","minInterest":"0.7","maxInterest":"1.5"});
  let data = useRef();
  const [counter, setCounter]=useState(0);
  const [error,setError]=useState('');
  const [hide, setHide]=useState("hidden");
  const [submitted, setSubmitted]=useState(false);
  const [getDataInitiated, setGetDataInitiated] = useState(false);
  //const [stocks, setStocks]=useState({});
  let stocks = useRef({});
  let [displayMessage, setDisplayMessage] = useState("Preparing ...");
  let findExpiredMissingSecuritiesInitiated = useRef(false);
  let getSecuritiesInfoInitiated = useRef(false);
  const [applyRowClassRules, setApplyRowClassRules] = useState(true);

  let apiCalled=useRef(0);
  let symbolsDetails = useRef([]);

  const [clickedRowData, setClickedRowData] = useState(null);
  let  rowData = useRef([]);
  let columnNameCellClicked = useRef("");
  let selectedRowIndex = useRef(-1);
  let lastRowClickEvent = useRef();
  let [dataLoaded, setDataLoaded] = useState(false);
  const [showGrid, setShowGrid] = useState(true); 
  let totalColumnWidth = useRef(0);
  let lastGridRef = useRef();
  let lastGridApi = useRef();
  let lastGridContainer = useRef();
  let lastFirstDataRendered = useRef();
  const [savedGridState, setSavedGridState] = useState(null);
  let sortState = useRef();
  let lastRowIndex = useRef();
  let lastColumnState = useRef();
  let gridColumnApi = useRef();
  let cellToExpandIsClicked = useRef(false);
  let sortedColumns = useRef();

  

  const updateGridContainerWidth = () => {
    console.log("Executing updateGridContainerWidth")
    if (!gridRef.current) {
      console.log("updateGridContainerWidth : gridRef.current is not set, assigning lastGridRef.current");
      gridRef.current = lastGridRef.current;
    } else {
      /*totalColumnWidth.current = 0;
      gridRef.current.getAllDisplayedColumns().forEach(column => {
          totalColumnWidth.current += column.getActualWidth();
      });*/
    }
    console.log("updateGridContainerWidth : gridApi is available");
    console.log(gridRef.current);
    
    console.log("updateGridContainerWidth : totalColumnWidth = " + totalColumnWidth.current);

    // Update the container's width
    let gridContainer = document.querySelector('.ag-theme-balham');
    if (gridContainer !== null) {
      lastGridContainer.current = gridContainer;
    } else {
      gridContainer = lastGridContainer.current;
    }
    console.log ("updateGridContainerWidth : gridContainer =");
    console.log(gridContainer);
    /*if (gridContainer) {
        gridContainer.style.width = `${totalColumnWidth.current}px`;
        console.log("updateGridContainerWidth : gridContainer.style.width = " + gridContainer.style.width);
    }*/
    //setGridContainerUpdated(true);
  };

  const onBackButtonClick = () => {
    console.log ("Executing onBackButtonClick");
    console.log ("onBackButtonClick : before change showGrid = " + showGrid);
    restoreGridState();
    console.log(gridApi.current);
    
    console.log ("onBackButtonClick : calling updateGridContainerWidth();");
    //updateGridContainerWidth();
    setShowGrid(true); 
    //gridApi.current.refreshCells();
    console.log ("onBackButtonClick : after change showGrid = " + showGrid);
    
    //restoreGridState();
    //onFirstDataRendered(lastFirstDataRendered.current);
    //
    
  };

  const DetailsTable = ({ data, onBack }) => {
    if (!data) return null;

    const excludedFields = ['id', 'details', 'expanded']; // Fields to exclude
    const filteredData = Object.entries(data).filter(([key]) => !excludedFields.includes(key));
    
    // Pair up the data entries for two columns per row
    const dataPairs = [];
    for (let i = 0; i < filteredData.length; i += 2) {
        dataPairs.push(filteredData.slice(i, i + 2));
    }

    return (
        <div style={{ textAlign: "center" }}>
            <table style={{ margin: "0 auto", borderCollapse: "collapse", width: "100%", maxWidth: "800px" }}>
                <tbody>
                    {dataPairs.map((pair, index) => (
                        <tr key={index}>
                            {pair.map(([key, value]) => (
                                <React.Fragment key={key}>
                                    <td style={{ fontWeight: "bold", border: "1px solid black", padding: "8px", backgroundColor: "lightgrey" }}>{key}</td>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>
                                      {Math.abs(value) >= 1000 ? value.toLocaleString() : value}
                                    </td>
                                </React.Fragment>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            
            <button onClick={onBack} style={{ marginTop: "20px", border: "none", background: "none", cursor: "pointer" }}>
              <img src="back.png" alt="Back" title="Back" style={{ width: '50px', height: '50px' }} />
            </button>
        </div>
    );
  };


/*<button onClick={onBack} style={{ marginTop: "20px" }}>Back</button>
<Button style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={onBack}>
              <img src="back.png" alt="Back" title="Back" style={{ marginRight: '5px', width: '33%', marginTop: "20px" }} />
            </Button>*/

  const saveGridState = () => {
    console.log("Executing saveGridState");
    if (!gridApi.current) return;

    // Example of saving row data and column state
    const rowData = [];
    console.log(gridApi.current);
    gridApi.current.forEachNode(node => rowData.push(node.data));
    const columnState = gridApi.current.getColumnState();

    setSavedGridState({ rowData, columnState });
  };

  const restoreGridState = () => {
    console.log("Executing restoreGridState");
    gridApi.current = lastGridApi.current;
    if (!gridApi.current || !savedGridState) return;

    // Set row data and column state
    console.log(gridApi.current);
    gridApi.current.setRowData(savedGridState.rowData);
    gridApi.current.setColumnState(savedGridState.columnState);
    gridRef.current.api.setColumnState(lastColumnState.current);
    console.log(gridRef.current.getColumnState());
    const rankingSortModel = JSON.parse(localStorage.getItem('rankingSortState'));
    if (rankingSortModel) {
        gridColumnApi.setSortModel([rankingSortModel]);
    }
  };

  const onSortChanged = (event) => {
    console.log ("Executing onSortChanged");
    console.log(event);
    console.log(gridApi.current);
    setApplyRowClassRules(true);
    // Save the sort state
    if (gridApi.current && gridColumnApi.current) {
      // Get the current state of all columns
      const columnState = gridColumnApi.current.getColumnState();
  
      // Filter out the columns that have sorting applied
      sortedColumns.current = columnState.filter(col => col.sort);
  
      // Save the sorted columns state
      localStorage.setItem('sortedColumns', JSON.stringify(sortedColumns));
    }
  };

  function onCellClicked(event) {
    console.log("Executing onCellClicked");
    console.log(event);
    console.log(gridApi.current);
    localStorage.setItem('selectedRowIndex', event.rowIndex);

    const rowIndex = event.rowIndex;
    //const rowIndex = gridApi.current.getLastDisplayedRow();
    console.log("onCellClicked : rowindex = " + event.rowIndex);
    console.log("onCellClicked : gridApi.current.getFirstDisplayedRow() = " + gridApi.current.getFirstDisplayedRow());
    console.log("onCellClicked : gridApi.current.getLastDisplayedRow() = " + gridApi.current.getLastDisplayedRow());
    console.log("onCellClicked : gridApi.current.getDisplayedRowCount() = " + gridApi.current.getDisplayedRowCount());
    console.log(gridApi.current.getRowNode(rowIndex));
    console.log(gridApi.current.getRowNode(gridApi.current.getFirstDisplayedRow()));
    console.log(gridApi.current.getRowNode(gridApi.current.getLastDisplayedRow()));
    console.log(gridApi.current.getVerticalPixelRange());
    const rowNode = gridApi.current.getRowNode(rowIndex);

    //const rowHeight = gridApi.current.getRowHeight(); // Assuming uniform row height
    const rowHeight = 25;
    const scrollTop = gridApi.current.getVerticalPixelRange().top; // Current scroll position

    // Approximate position of the row from the top of the grid
    const rowPosition = rowNode.rowIndex * rowHeight - scrollTop;
    console.log("onCellClicked : rowPosition = " + rowPosition);
    localStorage.setItem('selectedRowPosition', rowPosition);

    //localStorage.setItem('selectedRowIndex',gridApi.current.getLastDisplayedRow());
    if (event.colDef.field === "details") {
      cellToExpandIsClicked.current = true;
      lastGridRef.current = gridRef.current;
      lastGridApi.current = gridApi.current;
      totalColumnWidth.current = 0;
      gridRef.current.api.getAllDisplayedColumns().forEach(column => {
          totalColumnWidth.current += column.getActualWidth();
      });
      if (gridRef.current) {
        console.log("onCellClicked : rowindex = " + event.rowIndex);
        localStorage.setItem('selectedRowIndex', parseInt(event.rowIndex));
        //localStorage.setItem('selectedRowIndex', rowIndex);
        const rowData = [];
        gridRef.current.api.forEachNode(node => rowData.push(node.data));
        lastColumnState.current = gridRef.current.api.getColumnState();
        gridColumnApi.current = event.api;
        console.log(lastColumnState.current);
        console.log(gridApi.current);
        console.log(gridRef.current);
      }
      //setGridContainerUpdated(false);
      setClickedRowData(event.data); // Set the text you want to display
      setShowGrid(false); // Hide the grid
    }
  }

  const getRowHeight = (params) => {
    console.log("Executing getRowHeight");
    console.log("getRowHeight : params =");
    console.log(params);
    //if (params.data.expanded) {
    if (typeof(params) !== "undefined") {
      if (rowData.current[params.data.id].expanded) {
          //params.data.details = "v";
          return 200; // Expanded row height
      } 
    }
    return 25; // Default row height
  };

  const getUserInfo = async () => {
    try {
      await Auth.fetchAuthSession();
      // Retrieve the user's email address
      const user = await Auth.fetchUserAttributes();
      console.log("getUserInfo : email = " + JSON.stringify(user.email));
      email.current = await user.email;
      return "done";
    } catch (error) {
      console.log("Error in getUserInfo: ", error);
      return "error";
    }
  };

  const updateEtfsInfo = async () => {
    console.log("Executing updateEtfsInfo");
    if (!stocksDownloaded.current) {
      try {
          // Replace with the appropriate method (get, post, etc.) and add parameters if needed
          //const response = await axios.get('https://2fydft663cxnyhm3lsdvrp2bxi0celyp.lambda-url.us-east-1.on.aws/');
          const response = await axios.get('https://23vnqvuq4u6tt3omjgiu5xogmy0hyndz.lambda-url.us-east-1.on.aws/');
          await console.log('updateEtfsInfo : response = ', response.data);
          //let etfStocks = JSON.parse(response.data);
          let etfStocks = response.data;
          stocks.current = await {"stocks" : etfStocks};
          stocksDownloaded.current = await true;
          //return response.data;
      } catch (error) {
          console.error('Error calling updateEtfsInfo:', error);
          // Handle error accordingly
      }
    }
  };

  async function getEtfs() {
    await console.log("Executing getEtfs");
    if (!stocksDownloaded.current) {
      /*let etfsArr = [];
      for (let symInd = 0; symInd < symbolsDetails.current.length; symInd++) {
        if (symbolsDetails.current[symInd]["type"] === "ETF") {
          etfsArr.push(symbolsDetails.current[symInd]["symbol"]);
        }
      }*/
      stocks.current = await {"stocks" : ["GETFROMDB"]};
      stocksDownloaded.current = await true;
      await console.log("getEtfs : stocks.current = ");
      await console.log(stocks.current);
    }
  }

  let initString="";
  let stocksDownloaded = useRef(false);

  async function downloadStocks(file) {
    if (!stocksDownloaded.current) {
      await console.log("Executing downloadStocks");
      stocks.current = {};
      await Storage.get(file, { level: 'public', download:true })
      .then (async result => {
        stocksDownloaded.current = await true;
        await console.log(result.Body.text());
        await result.Body.text()
        .then(async string => { 
          await console.log("downloadStocks: data from file : string = "+string);
          initString=JSON.parse(string);
          await console.log("downloadStocks : before then : initString = "+JSON.stringify(initString));
          await console.log("downloadStocks : stocks.current = " + JSON.stringify(stocks.current));
          if (JSON.stringify(stocks.current)!==JSON.stringify(initString)) {
            await console.log("downloadStocks : I am in if stocks = "+JSON.stringify(stocks.current));
            await console.log (initString);
            //await setStocks(initString)
            stocks.current = initString;
            await console.log ("downloadStocks : stocks.current after being set");
            await console.log(stocks.current);
          } else {
            await console.log (" stocks.current.stocks are set");
          }
        })
        .catch(error => {
          console.log(error.message);
          errorState.current = true;
          setDisplayMessage(error.message);
        })
      })
      .catch(error => {
        console.log(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
      
    }
    console.log("downloadStocks : completed");
  }

  async function listFiles() {
    await console.log("Executing listFiles");
    data.current = await JSON.parse(await downloadFile(email.current));
  }

  async function getSymbolsDetails(){
    await console.log("Executing getSymbolsDetails");
    path = '/getSymbolsDetails';
    var passApiStr=await path;
    await console.log("getSymbolsDetails : passApiStr = "+ passApiStr);
    //await Promise.resolve(await get(myAPI, passApiStr))
    const response = await get({
      apiName: myAPI,
      path: passApiStr,
    }).response;
    let resp = await response.body.json();
    await Promise.resolve(resp)
      .then(async () =>{
        
        await console.log("getSymbolsDetails : received symbols details = ");
        await console.log(resp);
        //symbolsDetails.current = await JSON.stringify(resp);
        symbolsDetails.current = await resp;
        console.log("getSymbolsDetails : symbolsDetails.current.length = " + symbolsDetails.current.length);
          
    })
    .catch(error => {
      console.log(error.message);
      errorState.current = true;
      setDisplayMessage(error.message);
    }) 
  }

  let analysis = useRef([]);

  let recLst=[];
  let indRecLst=0;

  let maxChg="";
  let avgChg="";
  let wAvgChg="";
  let maxChgArrStr=[];
  let maxChgArrNum=[];
  let chgPercentCnt=0;
  let sumChgPercent=0;
  let wSumChgPercent=0;
  let wSum=0;
  let cnt33=0;
  let cnt66=0;
  let cnt100=0;
  let profitResponse="";
  let daysNowToExp = useRef();
  let errorState = useRef(false);


  let strategyInitiated = useRef(false);
  
const [rowDataState,setRowDataSate]=useState([]);
  
const [showLoading, setShowLoading] = useState(true);
let gridApi = useRef(null); 
let numOfStocks = useRef(0);
let countOfResponses = useRef(0);
let countRef = useRef(0);
//let [securitiesDbUpdated, setSecuritiesDbUpdated] = useState(false)
let securitiesDbUpdated = useRef(false);

function generateLimitTimestamp(offsetTimeInSeconds) {
  console.log("Executing generateTimestamp");
  const currentDate = new Date();
  let currentTimestamp = currentDate.getTime();
  let currentTimestampInt = parseInt(currentTimestamp);
  currentTimestampInt = parseInt(currentTimestampInt / 1000);
  let offsetTimestampInt = currentTimestampInt - parseInt(offsetTimeInSeconds);
  let offsetTimestamp = offsetTimestampInt.toString();
  currentTimestamp = currentTimestampInt.toString();
  console.log('Current timestamp:', currentTimestamp);
  console.log('Offset timestamp:', offsetTimestamp);

  return offsetTimestamp;
}

function encodeData(txt) {
  txt = txt.replace(/\{/g,"%7B");
  txt = txt.replace(/\}/g,"%7D");
  txt = txt.replace(/\[/g,"%5B");
  txt = txt.replace(/\]/g,"%5D");
  txt = txt.replace(/\:/g,"%3A");
  txt = txt.replace(/ /g,"%20");
  txt = txt.replace(/\,/g,"%2C");
  txt = txt.replace(/\"/g,"%22");

  return(txt);
}

let stocksUpdate=[];

let strategyInd = useRef();
let getSecuritiesCalled = useRef(false);

async function getSecuritiesInfo(securities) {
  await console.log ("getSecuritiesInfoInitiated.current = " + getSecuritiesInfoInitiated.current);
  if (!getSecuritiesInfoInitiated.current && !errorState.current) {
    getSecuritiesInfoInitiated.current = true;
    await console.log("getSecuritiesInfo : securities = ");
    await console.log(securities);
    
    if (securities.length > 0) {
      var param = {"stocks" : securities}
      await console.log("getSecuritiesInfo : response in param = " + JSON.stringify(param));
      var path = '/getSecuritiesInfo';
      var passApiStr=path + "/" + JSON.stringify(param);
      await console.log ("getSecuritiesInfo : passApistr = " + passApiStr);
      passApiStr = await encodeData(passApiStr);
      await console.log ("getSecuritiesInfo : passApistr encoded = " + passApiStr);
      //await Promise.resolve(await get(myAPI, passApistr))
      const response = await get({
        apiName: myAPI,
        path: passApiStr,
      }).response;
      let resp = await response.body.json();
      await Promise.resolve(resp)  
      .then(() => {
        getSecuritiesInfoInitiated.current = true;
        console.log ("getSecuritiesInfo : getSecuritiesInfoInitiated.current = " + getSecuritiesInfoInitiated.current);
      })
      .catch(error => {
        console.log(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
    }
    
  }  
}

useEffect(()=>{
  console.log("Executing useEffect");

  console.log(gridRef.current);

  if (gridRef.current !== null && typeof(gridRef.current) !== 'undefined') {
    gridApi.current = gridRef.current.api;
    console.log ("useEffect : gridApi = ")
    console.log(gridApi);
    console.log(gridRef.current.api);
    console.log(AgGridReact);
    console.log(AgGridReact.api);

    if (typeof(gridApi.current) !== 'undefined') {
      console.log("useEffect : adding event listener");
      gridApi.current.addEventListener('rowDataChanged', (event) => {
        console.log("useEffect : event.loading = ");
        console.log(event);
        if (!event.loading) {
          //setShowLoading(false);
        }
      });
    }
  }
  console.log("useEffect : type of stocks.stocks = "+ typeof(stocks.current.stocks));
  console.log("errorState.current = " + errorState.current);
  console.log("useEffect : securitiesDbUpdated = "+ securitiesDbUpdated.current);
  if (!securitiesDbUpdated.current && typeof(stocks.current.stocks)==='undefined' && !errorState.current) {
    Promise.resolve(getSymbolsDetails())
    .then(()=>{
      getUserInfo()
      .then(response =>{
        listFiles()
        .then(async response =>{
          //Promise.resolve(downloadStocks("stocks.json"))
          await setDisplayMessage("Getting market data for the symbol(s)");
          await Promise.resolve(updateEtfsInfo())
          .then(response => {
            try {
              var oneStock={"stocks":[]},i=0;
              var count = Object.keys(analysis.current).length;
              console.log("useEffect : count = "+count);
              console.log("useEffect : type of data = " + typeof(data.current));
              console.log("useEffect : stocks length = "+ stocks.current.stocks.length);
              console.log("useEffect : apiCalled.current = "+ apiCalled.current);
              //if (typeof(data)!='undefined' && stocks.current.stocks.length>0 && apiCalled.current===0){
                console.log("***************************************");
                console.log("useEffect : data = "+JSON.stringify(data.current));
                console.log("useEffect : stocks = " + JSON.stringify(stocks.current));
                console.log("useEffect : stocks length = "+ stocks.current["stocks"].length);
                apiCalled.current=1;
          
                // new approach start
          
                //{"pathParameters":{"stocks":["ACWI","ACWV","ACWX","AFTY"],"timestampLimit" : "1702158270"}}
          
                if (!securitiesDbUpdated.current && !errorState.current) {
                  //setDisplayMessage("Identifying symbol(s) with missing and expired data");
                  //findExpiredMissingSecurities()
                  //.then(async response => {
                    if (!getSecuritiesCalled.current  && !errorState.current) {
                      //setDisplayMessage("Getting market data for the symbol(s)");
                      //Promise.resolve(getSecuritiesInfo(stocks.current.stocks))
                        //.then(async response => {
                          securitiesDbUpdated.current = true;
                          if (!errorState.current) {
                            setDisplayMessage("Identifying profitable symbol(s)");
                          }
                          console.log ("useeffect : securitiesDbUpdated.current = " + securitiesDbUpdated.current);
                          console.log ("useeffect : starting loop for strategy");
                          console.log ("useeffect : stocks[stocks].length = " + stocks.current["stocks"].length);
  
                          var stocksStr = "";
                          for (strategyInd.current=0; strategyInd.current<stocks.current["stocks"].length; strategyInd.current++) {
                            stocksStr = stocksStr + stocks.current["stocks"][strategyInd.current];
                            if (strategyInd.current < stocks.current["stocks"].length - 1) {
                              stocksStr = stocksStr + ",";
                            }
                          }
  
                          /*for (strategyInd.current=0; strategyInd.current<stocks.current["stocks"].length; strategyInd.current++) {
                          //for (strategyInd.current=0; strategyInd.current<100; strategyInd.current++) {
                            oneStock["stocks"]=stocks.current["stocks"][strategyInd.current];
                            await console.log("useeffect : oneStock = "+JSON.stringify(oneStock));
                            var params={...data.current, ...oneStock};
                            await console.log("useEffect : params = "+JSON.stringify(params));
                            var paramsStr=JSON.stringify(params);
                            await console.log("useEffect : paramsStr = " + paramsStr);
                            displayMessage.current = "Processing " + oneStock["stocks"] + " ( " + (strategyInd.current + 1) + " out of " + stocks.current["stocks"].length + " )";
                            await strategy(paramsStr);
                          }*/
                          if (!errorState.current) {
                            getSecuritiesCalled.current = true;
                            oneStock["stocks"] = stocksStr;
                            var params={...data.current, ...oneStock};
                            var paramsStr=JSON.stringify(params);
                            Promise.resolve(strategyLongTerm(paramsStr))
                            .then(async (strategyAnalysis) => {
                              await console.log("useeffect : strategyAnalysis");
                              await console.log(strategyAnalysis);
                              count = Object.keys(strategyAnalysis).length;
                              let rowdata = [];
                              rowdata = await loadRowData(strategyAnalysis, daysNowToExp.current, "longterm");
                              await console.log("useeffect : rowDataState.length = " + rowDataState.length);
                              await console.log("useeffect : count = " + count);
                              await console.log("useeffect : rowdata.length = " + rowdata.length);
                              if (rowDataState.length<count && count!==0) {//rowDataState.length===0 && 
                                setRowDataSate(rowdata);
                                console.log("useeffect : rowdata = " + JSON.stringify(rowdata[0]));
                              }
                              if (!errorState.current) {
                                setShowLoading(false);
                              }
                            })
                            .catch(error => {
                              console.log(error.message);
                              errorState.current = true;
                              setDisplayMessage(error.message);
                            })
                          }
                       // })
                      
                    /*.catch(error => {
                      getSecuritiesCalled.current = true;
                      console.log(error.message);
                      errorState.current = true;
                      setDisplayMessage(error.message);
                    })*/
                  }
                //})
                } else {
                  /*console.log ("useeffect : starting loop for strategy");
                  var params={...data, ...stocks.current["stocks"]};
                  var paramsStr=JSON.stringify(params);
                  for (i=0; i<stocks.current["stocks"].length; i++) {
                    oneStock["stocks"]=stocks.current["stocks"][i];
                    console.log("oneStock = "+JSON.stringify(oneStock));
                    var params={...data.current, ...oneStock};
                    console.log("useEffect : params = "+JSON.stringify(params));
                    var paramsStr=JSON.stringify(params);
                    console.log("useEffect : paramsStr = " + paramsStr);
                    displayMessage.current = "Processing " + oneStock["stocks"] + " ( " + (strategyInd.current + 1) + " out of " + stocks.current["stocks"].length + " )";
                    strategy(paramsStr);
                  }
                  setShowLoading(false);
                  */
                }
              //}
              
            } catch (e) {
              
            }
          })
          .catch(error => {
            console.log(error.message);
            errorState.current = true;
            setDisplayMessage(error.message);
          })
        })
        .catch(error => {
          console.log(error.message);
          errorState.current = true;
          setDisplayMessage(error.message);
        })
      })
      .catch(error => {
        console.log(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
    })
    .catch(error => {
      console.log(error.message);
      errorState.current = true;
      setDisplayMessage(error.message);
    })
  }  
  
},[dataLoaded, getDataInitiated, applyRowClassRules, showGrid])

function printStocks() {
  
  console.log("I'M IN PRINTSTOCK");
  console.log("analysis = "+JSON.stringify(analysis.current));
  console.log("analysis length = "+analysis.current.length);
  console.log("typeof analysis.length = "+ typeof analysis.current.length )
  return (
    <ul>
      {typeof analysis.current.length!=='undefined'
      ?
        <text>Yes</text>
      
      : <text>None</text>
    }
    </ul>
  )
}

const gridRef = useRef();

const columns=gridColumns(symbolsDetails.current, symbolsDetails.current, 0, "longterm");

const onSelectionChanged=() =>{
  console.log("Executing onSelectionChanged");
  var commitDelete="";
  let selectedRowsNumber=gridRef.current.getSelectedRows().length;
  console.log("gridRef.current.getSelectedRows().length = " + selectedRowsNumber);
  /*if (gridRef.current.getSelectedRows().length>0) {
    commitDelete="commit";
  } else {
    commitDelete="delete";
  }*/
  if (selectedRowsNumber>=numberOfChecked.current) {
    commitDelete="commit";
    numberOfChecked.current++;
  } else {
    commitDelete="delete";
    numberOfChecked.current--;
  }

  console.log("commitDelete = "+commitDelete);
  //const selectedRows = gridRef.current.getSelectedRows();
  
  
  console.log("gridRef");
  console.log(gridRef);
  console.log("getSelectedNodes");
  console.log(gridRef.current.getSelectedNodes());
  console.log("checkboxSelected");
  console.log(gridRef.current.checkboxSelected);
  console.log("getDisplayedRowCount = "+gridRef.current.getDisplayedRowCount());
  const selectedRowIndex=gridRef.current.getFocusedCell().rowIndex;
  console.log("selectedRowIndex = "+selectedRowIndex);
  console.log("getDisplayedRowAtIndex");
  console.log(gridRef.current.getDisplayedRowAtIndex(selectedRowIndex));

  const selectedRows = gridRef.current.getDisplayedRowAtIndex(selectedRowIndex).data;
  console.log("selectedRows");
  console.log(selectedRows);

  let rowJson={"security":{}};
  let dt=Date().toString();
  let timestamp=Date.parse(dt).toString();
  rowJson.security["id"]=timestamp;
  rowJson.security["timestamp"]=dt;
  rowJson.security["symbol"]=selectedRows.security.toUpperCase();
  rowJson.security["rank"]=selectedRows.ranking.toString();
  rowJson.security["offer"]=selectedRows.stockOffer.toString();
  rowJson.security["exp"]=selectedRows.expiration.toString();
  rowJson.security["strike"]=selectedRows.strike.toString();
  rowJson.security["bid"]=selectedRows.bid.toString();
  rowJson.security["ask"]=selectedRows.offer.toString();
  rowJson.security["int"]=selectedRows.openInterest.toString();
  rowJson.security["vol"]=selectedRows.volume.toString();
  rowJson.security["profit"]=selectedRows.profit.toString();
  rowJson.security["annual"]=selectedRows.annualProfit.toString();
  rowJson.security["depth"]=selectedRows.depth.toString();
  rowJson.security["wavg"]=selectedRows.wAvgChange.toString();
  rowJson.security["max"]=selectedRows.maxChange.toString();
  rowJson.security["volatility"]=selectedRows.impliedVol.toString();
  rowJson.security["volatilityPeriod"]=selectedRows.impliedPeriod.toString();
  rowJson.security["volMinPeriod"]=selectedRows.minPrice.toString();
  rowJson.security["volMaxPeriod"]=selectedRows.maxPrice.toString();
  rowJson.security["email"]=email.current;

  if (commitDelete==="commit") {
    path = '/storetransaction';
    let transaction = new URLSearchParams({ data: JSON.stringify(rowJson) }).toString();
    transaction = transaction.replace("data=","");
    transaction = transaction.replace(/"%3A"/g,/":"/)
    let passApiStr=path + "/" + transaction;
    //var passApistr=path + "/" + JSON.stringify(rowJson);
    console.log("onSelectionChanged : passApistr = " +passApiStr);
    get({
      apiName: myAPI,
      path: passApiStr,
    }).response
      .then(async resp =>{
        await console.log("onSelectionChanged : resp = ");
        await console.log(resp);
      })
      .catch(error => {
        console.log(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
  }

  if (commitDelete==="delete") {
    path = '/deletetransaction';
    let transaction = new URLSearchParams({ data: JSON.stringify(rowJson) }).toString();
    transaction = transaction.replace("data=","");
    transaction = transaction.replace(/"%3A"/g,/":"/)
    let passApiStr=path + "/" + transaction;
    console.log("onSelectionChanged : passApistr = " +passApiStr);
    get({
      apiName: myAPI,
      path: passApiStr,
    }).response
      .then(async resp =>{
        await console.log("onSelectionChanged : resp = ", resp);
      })
      .catch(error => {
        console.log(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
  }
  
  /*console.log("selected expiration = "+selectedRows[0].expirations);
  var timeStamp=Date.parse(selectedRows[0].expirations)/1000;
  console.log("timeStamp = "+timeStamp);
  daysNowToExp.current=(timeStamp-today)/60/60/24;
  console.log("onSelectionChanged : daysNowToExp = "+daysNowToExp.current);
  profitsForExpiration(timeStamp, customList.current);
  setListRequested(true);*/
}

function onFirstDataRendered(params) {
  console.log("Executing onFirstDataRendered");
  lastFirstDataRendered.current = params;
  console.log(params);
  var allColumnIds = [];
  params.api.getColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
  });
  params.api.autoSizeColumns(allColumnIds);
  if (typeof(gridApi.current) !== 'undefined') {
    //setShowLoading(false);
    console.log("onFirstDataRendered : adding event listener");
    /*gridApi.current.addEventListener('rowDataChanged', (event) => {
      console.log("onFirstDataRendered : event.loading = ");
      console.log(event);
      if (!event.loading) {
        //setShowLoading(false);
      }
    });*/
  }
  console.log ("onFirstDataRendered : cellToExpandIsClicked.current = " + cellToExpandIsClicked.current);
  if (cellToExpandIsClicked.current) {
    //const savedSortState = localStorage.getItem('sortedColumns');
    console.log(sortedColumns.current)
    const savedSortState = sortedColumns.current;
    if (savedSortState && gridColumnApi.current) {
      //const sortedColumns = JSON.parse(savedSortState);
      const sortedColumns = savedSortState;

      gridColumnApi.current.applyColumnState({
        state: sortedColumns,
        applyOrder: true,
      });
    }
    const selectedRowIndex = localStorage.getItem('selectedRowIndex');
    const selectedRowPosition = localStorage.getItem('selectedRowPosition');
    if (selectedRowIndex !== null) {
      params.api.ensureIndexVisible(parseInt(selectedRowIndex));
    }
    cellToExpandIsClicked.current = false;
  }
}

  if (getDataInitiated === false) {
    setGetDataInitiated(true);
  };
  const gridOptions = {
    //domLayout: 'autoHeight', // This is important for rendering HTML in cells
    // Add other grid options as needed
    onSortChanged: () => {
      // Set the flag to false when sorting starts
      setApplyRowClassRules(false);
    },
    onSortChanged: () => {
      // Set the flag back to true when sorting is finished
      //setApplyRowClassRules(true);
    },
  };

  const onGridReady = params => {
    console.log("Executing onGridReady");
    console.log(params);
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
  
    // Restore sort state and scroll position here if needed
  };

  return (
    
    <View className="AppFullScreen">
      {showGrid ? (
        <div className="ag-theme-balham" style={{width: '90%', height:500, maxWidth: '1480px'}}>
          {showLoading && (
            <div className="blink_me">{displayMessage}</div>
          )}
          <div className="green_text">Long Term - Analysis For All ETFs</div>
            <AgGridReact
              gridOptions={gridOptions}
              //rowClassRules={activeButton === 'inTheMoney' ? rowClassRules : null}
              rowData={rowDataState}
              columnDefs={columns}
              suppressRowClickSelection={true}
              rowSelection={'multiple'}
              ref={gridRef}
              onFirstDataRendered={onFirstDataRendered}
              //onSelectionChanged={onSelectionChanged}
              onSortChanged={onSortChanged}
              onCellClicked={onCellClicked}
              onGridReady={onGridReady}
            />
        </div>
      ) : (
        <DetailsTable data={clickedRowData} onBack={onBackButtonClick} />
      )}  
  
    </View>
  );
};

export default withAuthenticator(RunAllLongTerm);