import logo from './logo.svg';
import './App.css';

import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import React, { useEffect, useState, useRef, useContext } from 'react'
import AddStock from './AddStock';
import { Outlet, Link } from "react-router-dom";
import { useNavigate, Navigate } from 'react-router-dom';
import Settings from './Settings';
import { Button } from '@aws-amplify/ui-react';
import { useActiveButton } from './ActiveButtonContext';
import {ScreenSizeContext} from './App'

const myAPI = "api30769188"
const path = '/customers'; 

const IronCondorMenu = ({signOut}) => {
  const [input, setInput] = useState("");
  const [customers, setCustomers] = useState([]);
  //const [activeButton, setActiveButton] = useState('inTheMoney');
  const { activeButton, setActiveButton } = useActiveButton();
  let imageWidth = useRef("50%");

  const screenSize = useContext(ScreenSizeContext);
  console.log ("IronCondorMenu : width = " + screenSize.width);
  screenSize.width = window.innerWidth;
  console.log ("IronCondorMenu : width inner = " + screenSize.width);
  console.log ("IronCondorMenu : height = " + screenSize.height);
  console.log("IronCondorMenu : imageWidth.current = " +  imageWidth.current);

  if (screenSize.width < 694 ) {
    imageWidth.current = "50%";
    console.log("IronCondorMenu : imageWidth.current = " +  imageWidth.current);
  } else {
    imageWidth.current = "15%";
    console.log("IronCondorMenu : imageWidth.current = " +  imageWidth.current);
  }

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  const navigate = useNavigate();

  useEffect(()=>{
    navigate(input);
  },[input, activeButton])

//changed titles
  //Function to fetch from our backend and update customers array
  function getCustomer(e) {
    let customerId = e.input
    get(myAPI, path + "/" + customerId)
       .then(response => {
         console.log(response)
         let newCustomers = [...customers]
         newCustomers.push(response)
         setCustomers(newCustomers)

       })
       .catch(error => {
         console.log(error)
       })
  }

  return (
    
<div className='MenuStyle' style={{
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  justifyContent: 'center', 
  
}}>
  <h1 style={{ display: 'none' }}>Main Menu Iron Condor</h1>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/generateOptionsLegs"); 
      }}>
      Generate Options Legs
    </Button>
  </div>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/generateOptionsLegsSpy"); 
      }}>
      Long SPY 30 Days / Upper 5% / Lower 0.1%
    </Button>
  </div>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/generateOptionsLegsQqq"); 
      }}>
      Long QQQ 30 Days / Upper 5% / Lower 0.1%
    </Button>
  </div>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/generateOptionsLegsIwm"); 
      }}>
      Long IWM 30 Days / Upper 5% / Lower 0.1%
    </Button>
  </div>
  <div className="menu-row" style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
    <Button backgroundColor="lightgreen" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={()=>{
        setInput("/findEtfsForIc"); 
      }}>
      Find ETFs for Iron Condor
    </Button>
  </div>
  
  <div className="menu-row" style={{ width: imageWidth.current, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
      <Button title="My Portfolio" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%", flex: 1}} onClick={()=>{
        setInput("/myPortfolioIronCondor"); 
    }}>
        <img
        src="briefcase.png"
        alt="Briefcase Icon"
        title="My Portfolio" // Hover-over text
        style={{ marginRight: '5px', width: "50%", maxWidth: "33%" }}
      />  
    </Button>
    
    <Button title="Sign Out" style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%", flex: 1}} onClick={signOut}>
      <img src="exit.png" alt="Briefcase Icon" title="Sign Out" style={{ marginRight: '5px', width: "50%", maxWidth: "33%" }} />
    </Button>

  </div>
</div>
  )
}

export default IronCondorMenu;