import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef, useMemo } from 'react'

import { get } from '@aws-amplify/api-rest';
import * as Auth from '@aws-amplify/auth';
import awsconfig from './aws-exports';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { gridColumns } from './lib/grid';
import { sepElements } from "./lib/common";
import { loadRowData } from "./lib/common";
import { getUserInfo } from "./lib/common";
import { listFiles } from "./lib/common";
import { rowClassRules } from "./lib/grid";
import { printConsole } from './lib/utility'; 
import { downloadFile } from "./lib/common";
import { useActiveButton } from './ActiveButtonContext';
import { strategy } from "./lib/common";

import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { type } from "@testing-library/user-event/dist/type";


const myAPI="mktappapi";
let path = '/strategy';

const Find = ({ signOut }) => {
  console.log("Executing Find");
  const today=Math.floor((new Date()).getTime() / 1000);
  let numberOfDays=0;
  console.log("find : today = "+today);
  const navigate = useNavigate();
  const { activeButton } = useActiveButton();
  let numberOfChecked=useRef(0);

  //const [email, setEmail]=useState()
  let email = useRef();
  const [listRequested, setListRequested]=useState(false);
  const [expRequested, setExpRequested]=useState(false);
  const [startStrategy, setStartStrategy] = useState(false);
  const customList=useRef();
  const [days, setDays]=useState();
  const [forceRender, setForceRender]=useState(false);
  const [daysOffset, setDaysOffset]=useState();
  const [minInterest, setMinInyerest]=useState();
  const [maxInterest, setMaxInterest]=useState();
  const [settingsStr, setSettingsStr]=useState({"days":"30","daysOffset":"10","minInterest":"0.7","maxInterest":"1.5"});
  const [data,setData]=useState();
  const [counter, setCounter]=useState(0);
  const [error,setError]=useState('');
  const [hide, setHide]=useState("hidden");
  const [submitted, setSubmitted]=useState(false);
  //const [stocks, setStocks]=useState({});
  let stocks = useRef({});
  let [displayMessage, setDisplayMessage] = useState("Preparing ...");
  let errorState = useRef(false);

  let apiCalled=useRef(0);
  const gridRef = useRef();
  let symbolsDetails = useRef([]);


  const [clickedRowData, setClickedRowData] = useState(null);
  let  rowData = useRef([]);
  let columnNameCellClicked = useRef("");
  let selectedRowIndex = useRef(-1);
  let lastRowClickEvent = useRef();
  let [dataLoaded, setDataLoaded] = useState(false);
  const [showGrid, setShowGrid] = useState(true); 
  let totalColumnWidth = useRef(0);
  let lastGridRef = useRef();
  let lastGridApi = useRef();
  let lastGridContainer = useRef();
  let lastFirstDataRendered = useRef();
  const [savedGridState, setSavedGridState] = useState(null);
  let sortState = useRef();
  let lastRowIndex = useRef();
  let lastColumnState = useRef();
  let gridColumnApi = useRef();
  let cellToExpandIsClicked = useRef(false);
  let sortedColumns = useRef();
  const [applyRowClassRules, setApplyRowClassRules] = useState(true);
  const [getDataInitiated, setGetDataInitiated] = useState(false);



  const updateGridContainerWidth = () => {
    console.log("Executing updateGridContainerWidth")
    if (!gridRef.current) {
      console.log("updateGridContainerWidth : gridRef.current is not set, assigning lastGridRef.current");
      gridRef.current = lastGridRef.current;
    } else {
      /*totalColumnWidth.current = 0;
      gridRef.current.getAllDisplayedColumns().forEach(column => {
          totalColumnWidth.current += column.getActualWidth();
      });*/
    }
    console.log("updateGridContainerWidth : gridApi is available");
    console.log(gridRef.current);
    
    console.log("updateGridContainerWidth : totalColumnWidth = " + totalColumnWidth.current);

    // Update the container's width
    let gridContainer = document.querySelector('.ag-theme-balham');
    if (gridContainer !== null) {
      lastGridContainer.current = gridContainer;
    } else {
      gridContainer = lastGridContainer.current;
    }
    console.log ("updateGridContainerWidth : gridContainer =");
    console.log(gridContainer);
    /*if (gridContainer) {
        gridContainer.style.width = `${totalColumnWidth.current}px`;
        console.log("updateGridContainerWidth : gridContainer.style.width = " + gridContainer.style.width);
    }*/
    //setGridContainerUpdated(true);
  };

  const onBackButtonClick = () => {
    console.log ("Executing onBackButtonClick");
    console.log ("onBackButtonClick : before change showGrid = " + showGrid);
    restoreGridState();
    console.log(gridApi.current);
    
    console.log ("onBackButtonClick : calling updateGridContainerWidth();");
    //updateGridContainerWidth();
    setShowGrid(true); 
    //gridApi.current.refreshCells();
    console.log ("onBackButtonClick : after change showGrid = " + showGrid);
    
    //restoreGridState();
    //onFirstDataRendered(lastFirstDataRendered.current);
    //
    
  };

  const DetailsTable = ({ data, onBack }) => {
    if (!data) return null;

    const excludedFields = ['id', 'details', 'expanded']; // Fields to exclude
    const filteredData = Object.entries(data).filter(([key]) => !excludedFields.includes(key));
    
    // Pair up the data entries for two columns per row
    const dataPairs = [];
    for (let i = 0; i < filteredData.length; i += 2) {
        dataPairs.push(filteredData.slice(i, i + 2));
    }

    return (
        <div style={{ textAlign: "center" }}>
          
            <table style={{ margin: "0 auto", borderCollapse: "collapse", width: "100%", maxWidth: "800px" }}>
                <tbody>
                    {dataPairs.map((pair, index) => (
                        <tr key={index}>
                            {pair.map(([key, value]) => (
                                <React.Fragment key={key}>
                                    <td style={{ fontWeight: "bold", border: "1px solid black", padding: "8px", backgroundColor: "lightgrey" }}>{key}</td>
                                    <td style={{ border: "1px solid black", padding: "8px" }}>{value}</td>
                                </React.Fragment>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            
            <button onClick={onBack} style={{ marginTop: "20px", border: "none", background: "none", cursor: "pointer" }}>
              <img src="back.png" alt="Back" title="Back" style={{ width: '50px', height: '50px' }} />
            </button>
        </div>
    );
  };


/*<button onClick={onBack} style={{ marginTop: "20px" }}>Back</button>
<Button style={{ width: '33.3%', marginTop: "1%", marginBottom:"1%" }} onClick={onBack}>
              <img src="back.png" alt="Back" title="Back" style={{ marginRight: '5px', width: '33%', marginTop: "20px" }} />
            </Button>*/

  const saveGridState = () => {
    console.log("Executing saveGridState");
    if (!gridApi.current) return;

    // Example of saving row data and column state
    const rowData = [];
    console.log(gridApi.current);
    gridApi.current.forEachNode(node => rowData.push(node.data));
    const columnState = gridApi.current.getColumnState();

    setSavedGridState({ rowData, columnState });
  };

  const restoreGridState = () => {
    console.log("Executing restoreGridState");
    gridApi.current = lastGridApi.current;
    if (!gridApi.current || !savedGridState) return;

    // Set row data and column state
    console.log(gridApi.current);
    gridApi.current.setRowData(savedGridState.rowData);
    gridApi.current.setColumnState(savedGridState.columnState);
    gridRef.current.api.setColumnState(lastColumnState.current);
    console.log(gridRef.current.getColumnState());
    const rankingSortModel = JSON.parse(localStorage.getItem('rankingSortState'));
    if (rankingSortModel) {
        gridColumnApi.setSortModel([rankingSortModel]);
    }
  };

  const onSortChanged = (event) => {
    console.log ("Executing onSortChanged");
    console.log(event);
    console.log(gridApi.current);
    setApplyRowClassRules(true);
    // Save the sort state
    if (gridApi.current && gridColumnApi.current) {
      // Get the current state of all columns
      const columnState = gridColumnApi.current.getColumnState();
  
      // Filter out the columns that have sorting applied
      sortedColumns.current = columnState.filter(col => col.sort);
  
      // Save the sorted columns state
      localStorage.setItem('sortedColumns', JSON.stringify(sortedColumns));
    }
  };

  function onCellClicked(event) {
    console.log("Executing onCellClicked");
    console.log(event);
    console.log(gridApi.current);
    localStorage.setItem('selectedRowIndex', event.rowIndex);

    const rowIndex = event.rowIndex;
    //const rowIndex = gridApi.current.getLastDisplayedRow();
    console.log("onCellClicked : rowindex = " + event.rowIndex);
    console.log("onCellClicked : gridApi.current.getFirstDisplayedRow() = " + gridApi.current.getFirstDisplayedRowIndex());
    console.log("onCellClicked : gridApi.current.getLastDisplayedRow() = " + gridApi.current.getLastDisplayedRowIndex());
    console.log("onCellClicked : gridApi.current.getDisplayedRowCount() = " + gridApi.current.getDisplayedRowCount());
    console.log(gridApi.current.getRowNode(rowIndex));
    console.log(gridApi.current.getRowNode(gridApi.current.getFirstDisplayedRowIndex()));
    console.log(gridApi.current.getRowNode(gridApi.current.getLastDisplayedRowIndex()));
    console.log(gridApi.current.getVerticalPixelRange());
    const rowNode = gridApi.current.getRowNode(rowIndex);

    //const rowHeight = gridApi.current.getRowHeight(); // Assuming uniform row height
    const rowHeight = 25;
    const scrollTop = gridApi.current.getVerticalPixelRange().top; // Current scroll position

    // Approximate position of the row from the top of the grid
    const rowPosition = rowNode.rowIndex * rowHeight - scrollTop;
    console.log("onCellClicked : rowPosition = " + rowPosition);
    localStorage.setItem('selectedRowPosition', rowPosition);

    //localStorage.setItem('selectedRowIndex',gridApi.current.getLastDisplayedRow());
    if (event.colDef.field === "details") {
      cellToExpandIsClicked.current = true;
      lastGridRef.current = gridRef.current;
      lastGridApi.current = gridApi.current;
      totalColumnWidth.current = 0;
      gridRef.current.getAllDisplayedColumns().forEach(column => {
          totalColumnWidth.current += column.getActualWidth();
      });
      if (gridRef.current) {
        console.log("onCellClicked : rowindex = " + event.rowIndex);
        localStorage.setItem('selectedRowIndex', parseInt(event.rowIndex));
        //localStorage.setItem('selectedRowIndex', rowIndex);
        const rowData = [];
        gridRef.current.api.forEachNode(node => rowData.push(node.data));
        lastColumnState.current = gridRef.current.getColumnState();
        gridColumnApi.current = event.api;
        console.log(lastColumnState.current);
        console.log(gridApi.current);
        console.log(gridRef.current);
      }
      //setGridContainerUpdated(false);
      setClickedRowData(event.data); // Set the text you want to display
      setShowGrid(false); // Hide the grid
    }
  }

  const getRowHeight = (params) => {
    console.log("Executing getRowHeight");
    console.log("getRowHeight : params =");
    console.log(params);
    //if (params.data.expanded) {
    if (typeof(params) !== "undefined") {
      if (rowData.current[params.data.id].expanded) {
          //params.data.details = "v";
          return 200; // Expanded row height
      } 
    }
    return 25; // Default row height
  };

  

  const getUserInfo = async () => {
    try {
      await Auth.fetchAuthSession();
      // Retrieve the user's email address
      const user = await Auth.fetchUserAttributes();
      console.log("getUserInfo : email = " + JSON.stringify(user.email));
      email.current = await user.email;
      return "done";
    } catch (error) {
      console.log("Error in getUserInfo: ", error);
      return "error";
    }
  };

  let initString="";

  async function listFiles() {
    await console.log("Executing listFiles");
    setData(await JSON.parse(await downloadFile(email.current)));
  }

  async function getSymbolsDetails(){
    await console.log("Executing getSymbolsDetails");
    path = '/getSymbolsDetails';
    var passApiStr=await path;
    await console.log("getSymbolsDetails : passApiStr = "+ passApiStr);
    //await Promise.resolve(await get(myAPI, passApiStr))
    const response = await get({
      apiName: myAPI,
      path: passApiStr,
    }).response
    let resp = await response.body.json();
    await Promise.resolve(resp)
      .then(async () =>{
        
        await console.log("getSymbolsDetails : received symbols details = ");
        await console.log(resp);
        //symbolsDetails.current = await JSON.stringify(resp);
        symbolsDetails.current = await resp;
        console.log("getSymbolsDetails : symbolsDetails.current.length = " + symbolsDetails.current.length);
          
    })
    .catch(error => {
      console.log(error.message);
      errorState.current = true;
      setDisplayMessage(error.message);
    }) 
  }

  //const [analysis, setAnalysis]=useState([]);
  let analysis = useRef([]);

  let recLst=[];
  let indRecLst=0;

  let maxChg="";
  let avgChg="";
  let wAvgChg="";
  let profitResponse="";
  let maxChgArrStr=[];
  let maxChgArrNum=[];
  let chgPercentCnt=0;
  let sumChgPercent=0;
  let wSumChgPercent=0;
  let wSum=0;
  let cnt33=0;
  let cnt66=0;
  let cnt100=0;

  let daysNowToExp = useRef();

const [rowDataState,setRowDataSate]=useState([]);
const [rowDataExp, setRowDataExp]=useState({});
  
  const [showLoading, setShowLoading] = useState(true);
  //const [gridApi, setGridApi] = useState(null);
  let gridApi = useRef(null);
  let numOfStocks = useRef(1);
  let countOfResponses = useRef(0);
  
  
  useEffect(()=>{
    console.log("Executing useEffect");
    let paramsStr;
    console.log(gridRef);
    setShowLoading(true);
    
  
    if (gridRef.current !== null && typeof(gridRef.current) !== 'undefined') {
      gridApi.current = gridRef.current.api;
      //setGridApi(gridApi);
      console.log ("useEffect : gridApi = ")
      console.log (gridRef.current);
      console.log(gridApi.current);
      console.log(gridRef.current.api);
      console.log(AgGridReact);
      console.log(AgGridReact.api);
  
      if (typeof(gridApi.current) !== 'undefined') {
        console.log("useEffect : adding event listener");
        gridApi.current.addEventListener('rowDataChanged', (event) => {
          console.log("useEffect : event.loading = ");
          console.log(event);
          if (!event.loading) {
            //setShowLoading(false);
          }
        });
      }
    }
    getUserInfo()
    .then (() =>{
      //listFiles()
      //.then(() => {
        try {
          Promise.resolve(getSymbolsDetails())
          .then(()=>{
            var oneStock={"stocks":[]},i=0;
            var count = Object.keys(analysis.current).length;
            console.log("useEffect : count = "+count);
            console.log("useEffect : type of data = " + typeof(data));
            //console.log("useEffect : stocks length = "+ stocks.current.stocks.length);
            console.log("useEffect : apiCalled.current = "+ apiCalled.current);
            //if (typeof(data)!='undefined' && stocks.stocks.length>0 && apiCalled.current===0){
              console.log("***************************************");
              console.log("useEffect : data = "+JSON.stringify(data));
              console.log("useEffect : stocks = " + JSON.stringify(stocks.current));
              //console.log("useEffect : stocks length = "+ stocks.current["stocks"].length);
              apiCalled.current=1;
              try {
                for (i=0; i<stocks.current["stocks"].length; i++) {
                  oneStock["stocks"]=stocks.current["stocks"][i].toString().toUpperCase();
                  console.log("oneStock = "+JSON.stringify(oneStock));
                  var params={...data, ...oneStock};
                  
                };
                let daysStart = parseInt(daysNowToExp.current) - 2;
                let daysEnd = parseInt(daysNowToExp.current) + 2;
                //var passApistr=path + "/" + paramsJSON.days + "/" + paramsJSON.daysOffset + "/" + paramsJSON.minDepth + "/" + paramsJSON.stocks.toString() + "/" + activeButton;
                params.days = daysStart.toString();
                params.daysOffset = daysEnd.toString();
                params.minDepth = "0";
                paramsStr = JSON.stringify(params);
                console.log("useEffect : paramsStr = " + paramsStr);
                Promise.resolve(strategy(paramsStr, activeButton))
                .then(async (strategyAnalysis) => {
                  await console.log("useeffect : strategyAnalysis");
                  await console.log(strategyAnalysis);
                  count = Object.keys(strategyAnalysis).length;
                  let rowdata = [];
                  rowdata = await loadRowData(strategyAnalysis, daysNowToExp.current, activeButton);
                  await console.log("useeffect : rowDataState.length = " + rowDataState.length);
                  await console.log("useeffect : count = " + count);
                  await console.log("useeffect : rowdata.length = " + rowdata.length);
                  if (rowDataState.length<count && count!==0) {//rowDataState.length===0 && 
                    setRowDataSate(rowdata);
                    console.log("useeffect : rowdata = " + JSON.stringify(rowdata[0]));
                  }
                  
                  if (!errorState.current) {
                    await setShowLoading(false);
                  }
                })
                .catch(error => {
                  console.log(error.message);
                  setDisplayMessage(error.message);
                })
            } catch (error)  {}
          })
          .catch(error => {
            console.log(error.message);
            setDisplayMessage(error.message);
          }) 
            
          //}
        } catch (e) {
      }
    })
  //})
  
},[startStrategy, dataLoaded, getDataInitiated, applyRowClassRules, showGrid])


function onFirstDataRendered(params) {
  console.log("Executing onFirstDataRendered");
  lastFirstDataRendered.current = params;
  console.log(params);
  var allColumnIds = [];
  params.api.getColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
  });
  params.api.autoSizeColumns(allColumnIds);
  if (typeof(gridApi.current) !== 'undefined') {
    //setShowLoading(false);
    console.log("onFirstDataRendered : adding event listener");
    /*gridApi.current.addEventListener('rowDataChanged', (event) => {
      console.log("onFirstDataRendered : event.loading = ");
      console.log(event);
      if (!event.loading) {
        //setShowLoading(false);
      }
    });*/
  }
  console.log ("onFirstDataRendered : cellToExpandIsClicked.current = " + cellToExpandIsClicked.current);
  if (cellToExpandIsClicked.current) {
    //const savedSortState = localStorage.getItem('sortedColumns');
    console.log(sortedColumns.current)
    const savedSortState = sortedColumns.current;
    if (savedSortState && gridColumnApi.current) {
      //const sortedColumns = JSON.parse(savedSortState);
      const sortedColumns = savedSortState;

      gridColumnApi.current.applyColumnState({
        state: sortedColumns,
        applyOrder: true,
      });
    }
    const selectedRowIndex = localStorage.getItem('selectedRowIndex');
    const selectedRowPosition = localStorage.getItem('selectedRowPosition');
    if (selectedRowIndex !== null) {
      params.api.ensureIndexVisible(parseInt(selectedRowIndex));
    }
    cellToExpandIsClicked.current = false;
  }
}

const getData=()=>{
  console.log("Executing getData");
  if (startStrategy === false) {
    setStartStrategy(true);
  }

  const gridOptions = {
    //domLayout: 'autoHeight', // This is important for rendering HTML in cells
    // Add other grid options as needed
    onSortChanged: () => {
      // Set the flag to false when sorting starts
      setApplyRowClassRules(false);
    },
    onSortChanged: () => {
      // Set the flag back to true when sorting is finished
      //setApplyRowClassRules(true);
    },
  };

  const onGridReady = params => {
    console.log("Executing onGridReady");
    console.log(params);
    gridApi.current = params.api;
    gridColumnApi.current = params.api;
  
    // Restore sort state and scroll position here if needed
  };

  return (

    <View className="AppFullScreen">
      {showGrid ? (
        <div className="ag-theme-balham" style={{width: '90%', height:500, maxWidth: '1210px'}}>
          <div className="green_text">B/W - Analysis For Security / Expiration</div>
          {showLoading && (
            <div className="blink_me">{displayMessage}</div>
          )}
            <AgGridReact
              gridOptions={gridOptions}
              rowClassRules={activeButton === 'inTheMoney' ? rowClassRules : null}
              rowData={rowDataState}
              columnDefs={columns}
              suppressRowClickSelection={true}
              rowSelection={'multiple'}
              ref={gridRef}
              onFirstDataRendered={onFirstDataRendered}
              onSelectionChanged={onSelectionChanged}
              onSortChanged={onSortChanged}
              onCellClicked={onCellClicked}
              onGridReady={onGridReady}
            />
        </div>
      ) : (
        <DetailsTable data={clickedRowData} onBack={onBackButtonClick} />
      )}  
  
    </View>
  )
}

let stkExp={};

const findExpirations=async ()=>{
  await console.log ("Executing findExpirations")
  var expPath = '/expirations';


  var passApistr=expPath + "/" + customList.current;
  await console.log("findExpirations : passApiStr = "+passApistr);
  const response = await get({
    apiName: myAPI,
    path: passApistr,
  }).response;
  
  await console.log("findExpirations : response = " , response);
  let resp = await response.body.json()
  Promise.resolve(resp)
     .then(async () => {
       await console.log("findExpirations : response = " + JSON.stringify(resp));
       await setRowDataExp(resp);
       await setExpRequested(true);
       //let newCustomers = [...customers]
       //newCustomers.push(response)
       //setCustomers(newCustomers)

     })
     .catch(error => {
      console.log(error.message);
      setDisplayMessage(error.message);
    })
}

const inputList=()=>{
  console.log("Executing inputList");
  return (
    <div>
      <div className="green_text">B/W - Analysis For Security / Expiration</div>
      <form id="customListForm" name="scustomListForm">
        <label>Enter a security to get expirations
        <br></br>
          <input
            type="text"
            id="security"
            name="security"
            contentEditable="true"
            
            onChange={(e) => { console.log("e.target.value.customList = " + e.target.value); customList.current=e.target.value; console.log("customList = " + customList.current); } }
          ></input>
        </label>

      </form>
      <Button className="Button" onClick={() => { findExpirations(); } }>Find Expirations</Button>
    </div>
  )
}

const columns=gridColumns(symbolsDetails.current, symbolsDetails.current, 0, activeButton);

const onSelectionChanged=() =>{
  console.log("Executing onSelectionChanged");
  var commitDelete="";
  let selectedRowsNumber=gridRef.current.api.getSelectedRows().length;
  console.log("gridRef.current.getSelectedRows().length = " + selectedRowsNumber);

  if (selectedRowsNumber>=numberOfChecked.current) {
    commitDelete="commit";
    numberOfChecked.current++;
  } else {
    commitDelete="delete";
    numberOfChecked.current--;
  }

  console.log("commitDelete = "+commitDelete);
  //const selectedRows = gridRef.current.getSelectedRows();
  
  
  console.log("gridRef");
  console.log(gridRef);
  console.log("getSelectedNodes");
  console.log(gridRef.current.api.getSelectedNodes());
  console.log("checkboxSelected");
  console.log(gridRef.current.checkboxSelected);
  console.log("getDisplayedRowCount = "+gridRef.current.api.getDisplayedRowCount());
  const selectedRowIndex=gridRef.current.api.getFocusedCell().rowIndex;
  console.log("selectedRowIndex = "+selectedRowIndex);
  console.log("getDisplayedRowAtIndex");
  console.log(gridRef.current.api.getDisplayedRowAtIndex(selectedRowIndex));

  const selectedRows = gridRef.current.api.getDisplayedRowAtIndex(selectedRowIndex).data;
  console.log("selectedRows");
  console.log(selectedRows);

  let rowJson={"security":{}};
  let dt=Date().toString();
  let timestamp=Date.parse(dt).toString();
  rowJson.security["id"]=timestamp;
  rowJson.security["timestamp"]=dt;
  rowJson.security["symbol"]=selectedRows.security.toUpperCase();
  rowJson.security["rank"]=selectedRows.ranking.toString();
  rowJson.security["offer"]=selectedRows.stockOffer.toString();
  rowJson.security["exp"]=selectedRows.expiration.toString();
  rowJson.security["strike"]=selectedRows.strike.toString();
  rowJson.security["bid"]=selectedRows.bid.toString();
  rowJson.security["ask"]=selectedRows.offer.toString();
  rowJson.security["int"]=selectedRows.openInterest.toString();
  rowJson.security["vol"]=selectedRows.volume.toString();
  rowJson.security["profit"]=selectedRows.profit.toString();
  rowJson.security["annual"]=selectedRows.annualProfit.toString();
  rowJson.security["depth"]=selectedRows.depth.toString();
  rowJson.security["wavg"]=selectedRows.wAvgChange.toString();
  rowJson.security["max"]=selectedRows.maxChange.toString();
  rowJson.security["volatility"]=selectedRows.impliedVol.toString();
  rowJson.security["volatilityPeriod"]=selectedRows.impliedPeriod.toString();
  rowJson.security["volMinPeriod"]=selectedRows.minPrice.toString();
  rowJson.security["volMaxPeriod"]=selectedRows.maxPrice.toString();
  rowJson.security["email"]=email.current;

  if (commitDelete==="commit") {
    path = '/storetransaction';
    let transaction = new URLSearchParams({ data: JSON.stringify(rowJson) }).toString();
    transaction = transaction.replace("data=","");
    transaction = transaction.replace(/"%3A"/g,/":"/)
    let passApistr=path + "/" + transaction;
    console.log("onSelectionChanged : passApistr = " +passApistr);
    get({
      apiName: myAPI,
      path: passApistr,
    }).response
      .then(async resp =>{
        await console.log("onSelectionChanged : resp = ", resp);
      })
      .catch(error => {
        console.log(error.message);
        setDisplayMessage(error.message);
      })
  }

  if (commitDelete==="delete") {
    path = '/deletetransaction';
    let transaction = new URLSearchParams({ data: JSON.stringify(rowJson) }).toString();
    transaction = transaction.replace("data=","");
    transaction = transaction.replace(/"%3A"/g,/":"/)
    let passApistr=path + "/" + transaction;
    console.log("onSelectionChanged : passApistr = " +passApistr);
    get({
      apiName: myAPI,
      path: passApistr,
    }).response
      .then(async resp =>{
        await console.log("onSelectionChanged : resp = ", resp);
      })
      .catch(error => {
        console.log(error.message);
        setDisplayMessage(error.message);
      })
  }

}

const onSelectionChangedExp=() =>{
  console.log("Executing onSelectionChangedExp");
  const selectedRows = gridRef.current.api.getSelectedRows();
  console.log("onSelectionChangedExp : selected expiration = "+selectedRows[0].expirations);
  var timeStamp=Date.parse(selectedRows[0].expirations)/1000;
  console.log("onSelectionChangedExp : timeStamp = "+timeStamp);
  daysNowToExp.current=(timeStamp-today)/60/60/24;
  console.log("onSelectionChangedExp : daysNowToExp = "+daysNowToExp.current);
  stocks.current = {"stocks" : [customList.current]};
  console.log("onSelectionChangedExp : stocks.current = " + JSON.stringify(customList.current));
  setListRequested(true);
}

let rowDataPubExp=[];

const selectExp=()=>{
  console.log("Executing selectExp");
  console.log("selectExp : rowdataExp = "+JSON.stringify(rowDataExp));

  for (let key=0; key<rowDataExp.expDates.length; key++) {
    rowDataPubExp[key]={
      expirations: (new Date(Number(rowDataExp.expDates[key])*1000)).toUTCString(),
      
    }
  }
  console.log("selectExp : rowDataPubExp");
  console.log(rowDataPubExp);



  const columnsPubExp=[
    {headerName: "Expirations", field: "expirations", sortable: true, filter: 'agNumberColumnFilter', resizable: true, minWidth:280},
  ];
  return (
    <div className="AppFullScreen">
      <div className="MenuStyle">
      <div className="ag-theme-balham" style={{height:500, width:320}}>
        <div className="green_text">B/W - Analysis For Security / Expiration</div>
            <AgGridReact
              ref={gridRef}
              rowData={rowDataPubExp}
              columnDefs={columnsPubExp}
              rowSelection={'single'}
              onSelectionChanged={onSelectionChangedExp}
            />
      
      </div>
    
      </div>
      
    </div>
    );
  }

const displayOption=()=>{
  if (listRequested) {
    return getData();
  } else {
    if (expRequested) {
      return selectExp();
    } else {
      return inputList();
    }
  }
}

  console.log("listRequested = "+listRequested);
  return (
    
    <div className="AppFullScreen">
      
      {displayOption()}
      
      
    </div>
  );
};

export default withAuthenticator(Find);