import React from "react";
import ReactDOM from 'react-dom/client';
import { useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useRef, useMemo } from 'react';
import * as Auth from '@aws-amplify/auth';
import { get } from '@aws-amplify/api-rest';
import awsconfig from './aws-exports';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { gridColumns } from './lib/grid';
import { sepElements } from "./lib/common";
import { loadRowData } from "./lib/common";
import { getUserInfo } from "./lib/common";
import { listFiles } from "./lib/common";
import { rowClassRules } from "./lib/grid";
import { printConsole } from './lib/utility'; 
import { downloadFile } from "./lib/common";

import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { type } from "@testing-library/user-event/dist/type";
const axios = require('axios');


const myAPI="mktappapi";
let path = '/strategy';

const mboum=require('mboummarketdatalib');

const RunDayGainers = ({ signOut }) => {
  console.log("I am in RunDayGainers");
  const navigate = useNavigate();

  let numberOfChecked=useRef(0);

  //const [email, setEmail]=useState()
  let email = useRef();
  let errorState = useRef(false);
  const [days, setDays]=useState();
  const [forceRender, setForceRender]=useState(false);
  const [daysOffset, setDaysOffset]=useState();
  const [minInterest, setMinInyerest]=useState();
  const [maxInterest, setMaxInterest]=useState();
  const [settingsStr, setSettingsStr]=useState({"days":"30","daysOffset":"10","minInterest":"0.7","maxInterest":"1.5"});
  let data = useRef();
  const [counter, setCounter]=useState(0);
  const [error,setError]=useState('');
  const [hide, setHide]=useState("hidden");
  const [submitted, setSubmitted]=useState(false);
  //const [stocks, setStocks]=useState({});
  let stocks = useRef({});
  let [displayMessage, setDisplayMessage] = useState("Preparing ...");
  const [getDataInitiated, setGetDataInitiated] = useState(false);

  let apiCalled=useRef(0);

  /*async function getUserInfo() {
    console.log("In getUserInfo");
    return (
      new Promise((resolve, reject)=>{
        Auth.currentAuthenticatedUser().then(user=>{
          console.log("getUserInfo : email = "+JSON.stringify(user.attributes.email));
          email.current = user.attributes.email;
          console.log("getuser email = "+email.current);
        })
        //setTimeout(() => resolve("done"), 1000);
      })
    )
  }*/

    const getUserInfo = async () => {
      try {
        await Auth.fetchAuthSession();
        // Retrieve the user's email address
        const user = await Auth.fetchUserAttributes();
        printConsole("getUserInfo : email = " + JSON.stringify(user.attributes.email));
        email.current = await user.attributes.email;
        return "done";
      } catch (error) {
        printConsole("Error in getUserInfo: ", error);
        return "error";
      }
    };

  let initString="";

  async function listFiles() {
    await printConsole("Executing listFiles");
    data.current = await JSON.parse(await downloadFile(email.current));
  }

  async function getStocks(collection){
    await console.log("Executing getStocks");
    path = '/getSecuritiesCollection';
    var passApiStr=await path + "/"+collection;
    await console.log("getStocks : passApiStr = "+ passApiStr);
    await Promise.resolve(await get(myAPI, passApiStr))
      .then(async resp =>{
        try {
          await console.log("getStocks : received stocks = ");
          await console.log(resp);
          if (resp.stocks.length === 0) {
            await setShowLoading(false);
          }
          if (await JSON.stringify(stocks) !== await JSON.stringify(resp)) {
            await console.log("getStocks : I am in if \n stocks = "+JSON.stringify(stocks));
            stocks.current = await resp;
            await console.log ("getStocks : stocks.current.stocks.length = " + stocks.current.stocks.length);
            if (stocks.current.stocks.length > 600) {
              let tmpArr = [];
              for (let stkInd = 0; stkInd < 600; stkInd++) {
                tmpArr.push(stocks.current.stocks[stkInd])
              }
              stocks.current.stocks = tmpArr;
              await console.log ("getStocks : stocks.current.stocks.length = " + stocks.current.stocks.length);
            }
          }
        } catch(error) {};
        return initString;
    })
    .catch(error => {
      printConsole(error.message);
      errorState.current = true;
      setDisplayMessage(error.message);
    }) 
  }

  //const [analysis, setAnalysis]=useState([]);
  let analysis = useRef([]);

  let recLst=[];
  let indRecLst=0;

  let maxChg="";
  let avgChg="";
  let wAvgChg="";
  let maxChgArrStr=[];
  let maxChgArrNum=[];
  let chgPercentCnt=0;
  let sumChgPercent=0;
  let wSumChgPercent=0;
  let wSum=0;
  let cnt33=0;
  let cnt66=0;
  let cnt100=0;
  let profitResponse="";
  let daysNowToExp = useRef();

  

  let strategyInitiated = useRef(false);
  async function strategy(params) {
    await console.log ("strategy : strategyInitiated.current = " + strategyInitiated.current);
    //if (!strategyInitiated.current) {
      strategyInitiated.current = true;
      await printConsole("Executing strategy");
      await printConsole("newstrategy : data = " + JSON.stringify(data.current));
      await printConsole("newstrategy : params = "+params);
      path = '/newstrategy';
      countOfResponses.current++;

      var paramsJSON=JSON.parse(params);

      var passApistr=path + "/" + paramsJSON.days + "/" +paramsJSON.daysOffset + "/"+paramsJSON.minDepth+"/"+paramsJSON.stocks.toString();
      await printConsole("strategy : passApiStr = "+passApistr);
      await get(myAPI, passApistr)
        .then(async response => {
          strategyInitiated.current = true;
          analysis.current = await response;
          await printConsole("strategy : response = " + JSON.stringify(response));
          
          //await printConsole("strategy : countOfResponses = " + countOfResponses.current);
          //setCounter(countOfResponses.current);
          var count = Object.keys(response).length;
          if (count>0){
              //await sepElements(response);
              await loadRowData();
            //})
          }
        })
        .catch(error => {
          printConsole(error.message);
          errorState.current = true;
          setDisplayMessage(error.message);
        })
        
    //}
  }

  
let rowdata=[];
//const [rowDataState,setRowDataSate]=useState([]);
let rowDataState = useRef([]);

function loadRowData() {
  printConsole("Executing loadRowData");
  printConsole ("loadRowData : analysis = "+ JSON.stringify(analysis.current));
  if (typeof(analysis.current)!='undefined') {
    var key=0;
    var count = Object.keys(analysis.current).length;
    printConsole ("loadRowData : analysis length= "+count);
    var rank=0;
    for (key=0; key<count; key++) {
      daysNowToExp.current=parseFloat(analysis.current[key]["daysNowToExp"]);
      var profit=parseFloat(analysis.current[key]["profit"]);
      var adjProfit=profit*30/daysNowToExp.current;
      var depth=parseFloat(analysis.current[key]["depth"]);
      var wAvgChange=parseFloat(analysis.current[key]["weightedAvgChgPercent"]);
      var minDepth=wAvgChange*0.75;
      if (adjProfit >= 0.9 && depth >= minDepth) {
        rank=2;
      } else if (adjProfit >= 0.6 && adjProfit <0.9 && depth >= minDepth) {
        rank=1;
      } else {
        rank=0;
      }
      if (analysis.current[key]["averageAnalystRating"].toString() === "null") {
        analysis.current[key]["averageAnalystRating"] = "";
      }
      if (analysis.current[key]["earningsTimestampStart"].toString() === "null") {
        analysis.current[key]["earningsTimestampStart"] = "";
      }
      rowdata[key]={
        security: analysis.current[key]["stock"],
        ranking: rank,
        stockOffer: parseFloat(analysis.current[key]["stockOffer"]),
        expiration: analysis.current[key]["expire"],
        strike: parseFloat(analysis.current[key]["strike"]),
        analystRating: analysis.current[key]["averageAnalystRating"],
        earningsDate: analysis.current[key]["earningsTimestampStart"],
        maxChange: parseFloat(analysis.current[key]["maxChangePercent"]),
        bid: parseFloat(analysis.current[key]["bid"]),
        offer: parseFloat(analysis.current[key]["ask"]),
        openInterest: parseInt(analysis.current[key]["openInterest"]),
        volume: parseInt(analysis.current[key]["volume"]),
        profit: parseFloat(analysis.current[key]["profit"]),
        annualProfit: parseFloat(analysis.current[key]["annualProfit"]),
        depth: parseFloat(analysis.current[key]["depth"]),
        wAvgChange: parseFloat(analysis.current[key]["weightedAvgChgPercent"]),
        impliedVol: parseFloat(analysis.current[key]["impliedVol"]),
        impliedPeriod: parseFloat(analysis.current[key]["impliedPeriod"]),
        minPrice: parseFloat(analysis.current[key]["minPrice"]),
        maxPrice: parseFloat(analysis.current[key]["maxPrice"])
      }
    }
    printConsole("loadRowData : typeof rowDataState = "+typeof(rowDataState.current));
    printConsole("loadRowData : length rowDataState = "+rowDataState.current.length);
    printConsole("loadRowData : count = "+count);
    if (rowDataState.current.length<count && count!=0) {//rowDataState.length===0 && 
      rowDataState.current = rowdata;
      printConsole("loadRowData : rowdata = " + JSON.stringify(rowdata[0]));
    }
    
  }
}

  
  
  const [showLoading, setShowLoading] = useState(true);
  //const [gridApi, setGridApi] = useState(null);
  let gridApi = useRef(null);
  let numOfStocks = useRef(0);
  let countOfResponses = useRef(0);
  let countRef = useRef(0);
  //let [securitiesDbUpdated, setSecuritiesDbUpdated] = useState(false);
  let securitiesDbUpdated = useRef(false);

  function generateLimitTimestamp(offsetTimeInSeconds) {
    console.log("Executing generateTimestamp");
    const currentDate = new Date();
    let currentTimestamp = currentDate.getTime();
    let currentTimestampInt = parseInt(currentTimestamp);
    currentTimestampInt = parseInt(currentTimestampInt / 1000);
    let offsetTimestampInt = currentTimestampInt - parseInt(offsetTimeInSeconds);
    let offsetTimestamp = offsetTimestampInt.toString();
    currentTimestamp = currentTimestampInt.toString();
    console.log('Current timestamp:', currentTimestamp);
    console.log('Offset timestamp:', offsetTimestamp);
  
    return offsetTimestamp;
  }

  function encodeData(txt) {
    txt = txt.replace(/\{/g,"%7B");
    txt = txt.replace(/\}/g,"%7D");
    txt = txt.replace(/\[/g,"%5B");
    txt = txt.replace(/\]/g,"%5D");
    txt = txt.replace(/\:/g,"%3A");
    txt = txt.replace(/ /g,"%20");
    txt = txt.replace(/\,/g,"%2C");
    txt = txt.replace(/\"/g,"%22");

    return(txt);
  }

  let stocksUpdate=[];

  let strategyInd = useRef();
  let getSecuritiesCalled = useRef(false);

  async function getSecuritiesInfo(securities) {
    await console.log("getSecuritiesInfo : securities = ");
    await console.log(securities);
    
      var param = {"stocks" : securities}
      await console.log("getSecuritiesInfo : response in param = " + JSON.stringify(param));
      var path = '/getSecuritiesInfo';
      var passApistr=path + "/" + await JSON.stringify(param);
      await console.log ("getSecuritiesInfo : passApistr = " + passApistr);
      passApistr = await encodeData(passApistr);
      await console.log ("getSecuritiesInfo : passApistr encoded = " + passApistr);
      await Promise.resolve(await get(myAPI, passApistr))
      .then (async () =>{
        await console.log ("getSecuritiesInfo : completed the update");
      })
      .catch(error => {
        printConsole(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
  }
  
  useEffect(()=>{
    console.log("Executing useEffect");
  
    console.log(gridRef);
  
    if (gridRef.current !== null) {
      gridApi.current = gridRef.current.api;
      //setGridApi(gridApi);
      console.log ("useEffect : gridApi = ")
      console.log (gridRef);
      console.log(gridApi.current);
      console.log(gridRef.current.api);
      console.log(AgGridReact);
      console.log(AgGridReact.api);
  
      if (typeof(gridApi.current) !== 'undefined') {
        console.log("useEffect : adding event listener");
        gridApi.current.addEventListener('rowDataChanged', async (event) => {
          await console.log("useEffect : event.loading = ");
          await console.log(event);
          if (!event.loading) {
            //setShowLoading(false);
          }
        });
      }
    }
    if (!securitiesDbUpdated.current && typeof(stocks.current.stocks)==='undefined') {
      Promise.resolve(getUserInfo())
      .then(async response =>{
        await Promise.resolve(await listFiles())
        .then(async response =>{
          if (typeof (stocks.current.stocks) === 'undefined') {
            await Promise.resolve(await getStocks("daygainers"))
          .then(async response => {
            try {
              var oneStock={"stocks":[]},i=0;
              var count = await Object.keys(analysis.current).length;
              await console.log("useEffect : count = "+count);
              await console.log("useEffect : type of data = " + typeof(data.current));
              await console.log("useEffect : stocks length = "+ stocks.current.stocks.length);
              await console.log("useEffect : apiCalled.current = "+ apiCalled.current);
              //if (typeof(data)!='undefined' && stocks.current.stocks.length>0 && apiCalled.current===0){
                await console.log("***************************************");
                await console.log("useEffect : data = "+JSON.stringify(data.current));
                await console.log("useEffect : stocks = " + JSON.stringify(stocks.current));
                await console.log("useEffect : stocks length = "+ stocks.current["stocks"].length);
                apiCalled.current=1;
          
                // new approach start
          
                //{"pathParameters":{"stocks":["ACWI","ACWV","ACWX","AFTY"],"timestampLimit" : "1702158270"}}
          
                if (!securitiesDbUpdated.current) {
                  setDisplayMessage("Identifying symbol(s) with missing and expired data");
                  //await Promise.resolve(await findExpiredMissingSecurities())
                  //.then(async response => {
                    if (!getSecuritiesCalled.current) {
                      setDisplayMessage("Getting data for the symbol(s)");
                      await Promise.resolve(await getSecuritiesInfo(stocks.current.stocks))
                        .then(async response => {
                          //await setSecuritiesDbUpdated(true);
                          securitiesDbUpdated.current = true;
                          if (!errorState.current) {
                            setDisplayMessage("Data for the symbol(s) is updated");
                          }
                          await console.log ("useeffect : securitiesDbUpdated.current = " + securitiesDbUpdated.current);
                          await console.log ("useeffect : starting loop for strategy");
                          await console.log ("useeffect : stocks[stocks].length = " + stocks.current["stocks"].length);
                          var stocksStr = "";
                          for (strategyInd.current=0; strategyInd.current<stocks.current["stocks"].length; strategyInd.current++) {
                            stocksStr = stocksStr + stocks.current["stocks"][strategyInd.current];
                            if (strategyInd.current < stocks.current["stocks"].length - 1) {
                              stocksStr = stocksStr + ",";
                            }
                          }
                          /*for (strategyInd.current=0; strategyInd.current<stocks.current["stocks"].length; strategyInd.current++) {
                          //for (strategyInd.current=0; strategyInd.current<100; strategyInd.current++) {
                            oneStock["stocks"]=stocks.current["stocks"][strategyInd.current];
                            await console.log("useeffect : oneStock = "+JSON.stringify(oneStock));
                            var params={...data.current, ...oneStock};
                            await console.log("useEffect : params = "+JSON.stringify(params));
                            var paramsStr=JSON.stringify(params);
                            await console.log("useEffect : paramsStr = " + paramsStr);
                            displayMessage.current = "Processing " + oneStock["stocks"] + " ( " + (strategyInd.current + 1) + " out of " + stocks.current["stocks"].length + " )";
                            await strategy(paramsStr);
                          }*/
                          getSecuritiesCalled.current = true;
                          oneStock["stocks"] = stocksStr;
                          var params={...data.current, ...oneStock};
                          var paramsStr=JSON.stringify(params);
                          await Promise.resolve(await strategy(paramsStr))
                          .then(() => {
                            if (!errorState.current) {
                              setShowLoading(false);
                            }
                          })
                          .catch(error => {
                            printConsole(error.message);
                            errorState.current = true;
                            setDisplayMessage(error.message);
                          })
                        })
                      
                    .catch(error => {
                      getSecuritiesCalled.current = true;
                      printConsole(error.message);
                      errorState.current = true;
                      setDisplayMessage(error.message)
                    })
                  }
                //})
                } else {
                  /*console.log ("useeffect : starting loop for strategy");
                  var params={...data, ...stocks.current["stocks"]};
                  var paramsStr=JSON.stringify(params);
                  for (i=0; i<stocks.current["stocks"].length; i++) {
                    oneStock["stocks"]=stocks.current["stocks"][i];
                    console.log("oneStock = "+JSON.stringify(oneStock));
                    var params={...data.current, ...oneStock};
                    console.log("useEffect : params = "+JSON.stringify(params));
                    var paramsStr=JSON.stringify(params);
                    console.log("useEffect : paramsStr = " + paramsStr);
                    displayMessage.current = "Processing " + oneStock["stocks"] + " ( " + (strategyInd.current + 1) + " out of " + stocks.current["stocks"].length + " )";
                    strategy(paramsStr);
                  }
                  setShowLoading(false);*/
                }
              //}
              
            } catch (e) {
              if (!errorState.current) {
                setShowLoading(false);
              }
            }
          })
          .catch(error => {
            printConsole(error.message);
            errorState.current = true;
            setDisplayMessage(error.message);
          })
          }
        })
        .catch(error => {
          printConsole(error.message);
          errorState.current = true;
          setDisplayMessage(error.message);
        })
      })
      .catch(error => {
        printConsole(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
    }

    },[getDataInitiated])

function printStocks() {
  
  console.log("I'M IN PRINTSTOCK");
  console.log("analysis = "+JSON.stringify(analysis));
  console.log("analysis length = "+analysis.length);
  console.log("typeof analysis.length = "+ typeof analysis.length )
  return (
    <ul>
      {typeof analysis.length!=='undefined'
      ?
        <text>Yes</text>
      
      : <text>None</text>
    }
    </ul>
  )
}

const gridRef = useRef();
const columns=gridColumns();

const onSelectionChanged=() =>{
  var commitDelete="";
  let selectedRowsNumber=gridRef.current.getSelectedRows().length;
  console.log("gridRef.current.getSelectedRows().length = " + selectedRowsNumber);
  /*if (gridRef.current.getSelectedRows().length>0) {
    commitDelete="commit";
  } else {
    commitDelete="delete";
  }*/
  if (selectedRowsNumber>=numberOfChecked.current) {
    commitDelete="commit";
    numberOfChecked.current++;
  } else {
    commitDelete="delete";
    numberOfChecked.current--;
  }

  console.log("commitDelete = "+commitDelete);
  //const selectedRows = gridRef.current.getSelectedRows();
  
  
  console.log("gridRef");
  console.log(gridRef);
  console.log("getSelectedNodes");
  console.log(gridRef.current.getSelectedNodes());
  console.log("checkboxSelected");
  console.log(gridRef.current.checkboxSelected);
  console.log("getDisplayedRowCount = "+gridRef.current.getDisplayedRowCount());
  const selectedRowIndex=gridRef.current.getFocusedCell().rowIndex;
  console.log("selectedRowIndex = "+selectedRowIndex);
  console.log("getDisplayedRowAtIndex");
  console.log(gridRef.current.getDisplayedRowAtIndex(selectedRowIndex));

  const selectedRows = gridRef.current.getDisplayedRowAtIndex(selectedRowIndex).data;
  console.log("selectedRows");
  console.log(selectedRows);

  let rowJson={"security":{}};
  let dt=Date().toString();
  let timestamp=Date.parse(dt).toString();
  rowJson.security["id"]=timestamp;
  rowJson.security["timestamp"]=dt;
  rowJson.security["symbol"]=selectedRows.security.toUpperCase();
  rowJson.security["rank"]=selectedRows.ranking.toString();
  rowJson.security["offer"]=selectedRows.stockOffer.toString();
  rowJson.security["exp"]=selectedRows.expiration.toString();
  rowJson.security["strike"]=selectedRows.strike.toString();
  rowJson.security["bid"]=selectedRows.bid.toString();
  rowJson.security["ask"]=selectedRows.offer.toString();
  rowJson.security["int"]=selectedRows.openInterest.toString();
  rowJson.security["vol"]=selectedRows.volume.toString();
  rowJson.security["profit"]=selectedRows.profit.toString();
  rowJson.security["annual"]=selectedRows.annualProfit.toString();
  rowJson.security["depth"]=selectedRows.depth.toString();
  rowJson.security["wavg"]=selectedRows.wAvgChange.toString();
  rowJson.security["max"]=selectedRows.maxChange.toString();
  rowJson.security["volatility"]=selectedRows.impliedVol.toString();
  rowJson.security["volatilityPeriod"]=selectedRows.impliedPeriod.toString();
  rowJson.security["volMinPeriod"]=selectedRows.minPrice.toString();
  rowJson.security["volMaxPeriod"]=selectedRows.maxPrice.toString();
  rowJson.security["email"]=email.current;

  if (commitDelete==="commit") {
    path = '/storetransaction';
    var passApistr=path + "/" + JSON.stringify(rowJson);
    console.log("onSelectionChanged : passApistr = " +passApistr);
    get(myAPI, passApistr)
      .then(async resp =>{
        await console.log("onSelectionChanged : resp = ");
        await console.log(resp);
      })
      .catch(error => {
        printConsole(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
  }

  if (commitDelete==="delete") {
    path = '/deletetransaction';
    var passApistr=path + "/" + JSON.stringify(rowJson);
    console.log("onSelectionChanged : passApistr = " +passApistr);
    get(myAPI, passApistr)
      .then(async resp =>{
        await console.log("onSelectionChanged : resp = ");
        await console.log(resp);
      })
      .catch(error => {
        printConsole(error.message);
        errorState.current = true;
        setDisplayMessage(error.message);
      })
  }
}

function onFirstDataRendered(params) {
  //params.api.sizeColumnsToFit();
  var allColumnIds = [];
  params.columngetAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
  });
  params.columnApi.autoSizeColumns(allColumnIds);
  if (typeof(gridApi.current) !== 'undefined') {
    //setShowLoading(false);
    console.log("useEffect : adding event listener");
    gridApi.current.addEventListener('rowDataChanged', (event) => {
      console.log("useEffect : event.loading = ");
      console.log(event);
      if (!event.loading) {
        //setShowLoading(false);
      }
    });
  }
}
  if (getDataInitiated === false) {
    setGetDataInitiated(true);
  };

  return (
    
    <View className="AppFullScreen">
        {/*loadRowData()*/}
        {/*console.log("rowDataState = "+rowDataState)*/}
        <div className="ag-theme-balham" style={{height:500}}>
          {showLoading && (
            <div className="blink_me">{displayMessage}</div>
          )}
            <AgGridReact
              rowClassRules={rowClassRules}
              rowData={rowDataState.current}
              columnDefs={columns}
              suppressRowClickSelection={true}
              rowSelection={'multiple'}
              ref={gridRef}
              onFirstDataRendered={onFirstDataRendered}
              onSelectionChanged={onSelectionChanged}
            />
               
                
        </div>
        
     
    </View>
  );
}

export default withAuthenticator(RunDayGainers);